// TODO: check if everything will be used
import device from 'current-device';

import { getVariablePairs } from './url.utils';
import { ENV, CLIENT } from '../config';

const test = 'test';
const development = 'development';
const stage = 'stage';
const release = 'testing';
const production = 'prod';
const apollo = 'apollo';
const netquest = 'netquest';
const trialStaging = 'trial-staging';
const trialProd = 'trial-prod';

export const isApollo = () => CLIENT === apollo;
export const isNetquestClient = () => CLIENT === netquest;

const isEnv = (environments, feature, date = '1996-01-01') => {
  if (feature === undefined && date === '1996-01-01') {
    return environments.includes(ENV);
  }
  return false;
};
export const isActive = (feature, date) => isEnv(undefined, feature, date);

export const isTest = (feature, date) => isEnv([test], feature, date);
export const isDev = (feature, date) => isEnv([development], feature, date);
export const isStage = (feature, date) => isEnv([development, stage], feature, date);
export const isTrial = (feature, date) => isEnv([trialStaging, trialProd], feature, date);
export const isRelease = (feature, date) => isEnv([development, stage, release], feature, date);
export const isProduction = (feature, date) => isEnv([development, stage, release, production], feature, date);

export const isOnlyDev = (feature, date) => isEnv([development], feature, date);
export const isOnlyStage = (feature, date) => isEnv([stage], feature, date);
export const isOnlyRelease = (feature, date) => isEnv([release, trialStaging], feature, date);
export const isOnlyProduction = (feature, date) => isEnv([production, trialProd], feature, date);
export const isOnlyReleaseOrProductionOrTrial = (feature, date) =>
  isEnv([release, production, trialProd, trialStaging], feature, date);
export const isOnlyReleaseAndProduction = (feature, date) => isEnv([release, production], feature, date);

export const isMobile = () => {
  const pair = getVariablePairs();
  return device.mobile() || pair.includes('forceMobile=1');
};

export const isTablet = () => {
  const pair = getVariablePairs();
  return device.tablet() || pair.includes('forceTablet=1');
};

export const isMobileOrTablet = () => {
  const pair = getVariablePairs();
  return device.tablet() || device.mobile() || pair.includes('forceMobile=1') || pair.includes('forceTablet=1');
};

export const shouldRenderMobile = () => {
  return isMobile();
};

export const shouldRenderTablet = () => {
  return isTablet();
};
