export const ON_CHANGE_ITEM = 'ON_CHANGE_ITEM';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SUBMIT_RESPONSES_REQUEST = 'SUBMIT_RESPONSES_REQUEST';
export const SUBMIT_RESPONSES_SUCCESS = 'SUBMIT_RESPONSES_SUCCESS';
export const SUBMIT_RESPONSES_FAILED = 'SUBMIT_RESPONSES_FAILED';
export const CLEAR_RESPONSES = 'CLEAR_RESPONSES';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const CAPTURE_CHANGE_LANGUAGE = 'CAPTURE_CHANGE_LANGUAGE';
export const CLEAR_QUESTION_RESPONSES = 'CLEAR_QUESTION_RESPONSES';
export const REDIRECT_SUBMIT = 'REDIRECT_SUBMIT';
export const CLEAR_DEPENDENT_QUESTIONS = 'CLEAR_DEPENDENT_QUESTIONS';
export const LOAD_RESPONSES = 'LOAD_RESPONSES';
export const SET_EXPERIMENTS_CONFIG = 'SET_EXPERIMENTS_CONFIG';
export const SET_EMBEDDED_VARIABLES = 'SET_EMBEDDED_VARIABLES';
export const SET_URL_VARIABLES_RESPONSES = 'SET_URL_VARIABLES_RESPONSES';
export const SET_SCREENERS_RESPONSES = 'SET_SCREENERS_RESPONSES';
