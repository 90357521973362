import { getCurrentEnv } from '../../config';

export enum Features {
  FEATURE0 = 'feature0',
  FEATURE1 = 'feature1',
  /** @deprecated */
  catalogForRank = 'catalogForRank',
  /** @deprecated */
  panel = 'panel',
  /** @deprecated */
  panel2 = 'panel2',
  conjoint = 'conjoint',
}

export enum DynamicEnvironments {
  /** @deprecated */
  catalogForRank = 'catalogForRank',
  testEnv = 'testEnv', // ONLY FOR TESTING PURPOSES
  /** @deprecated */
  panel = 'panel',
  panel2 = 'panel2',
  Conjoint = 'conjoint',
}

export enum Environments {
  prod = 'prod',
  staging = 'staging',
  litestaging = 'litestaging',
}

type FeatureEnable = {
  [feature in Features]: Set<Environments | DynamicEnvironments>;
};

type FeatureEnableInDynamicEnvironments = {
  [feature in Features]: Set<DynamicEnvironments>;
};

// Add dynamic environment here to enable the feature on it
const featureEnablementInDynamicEnvs: FeatureEnableInDynamicEnvironments = {
  // test features
  [Features.FEATURE0]: new Set([DynamicEnvironments.testEnv]),
  [Features.FEATURE1]: new Set([]),
  [Features.catalogForRank]: new Set([DynamicEnvironments.catalogForRank]),
  [Features.panel]: new Set([DynamicEnvironments.panel]),
  [Features.panel2]: new Set([DynamicEnvironments.panel2]),
  [Features.conjoint]: new Set([DynamicEnvironments.Conjoint]),
};

// Base feature enablement should come from the dynamic environments
const featureEnablement: FeatureEnable = featureEnablementInDynamicEnvs;

// Add feature here to enable it in staging
const featuresDeployedToStaging: Features[] = [];

featuresDeployedToStaging.forEach((feature) => {
  featureEnablement[feature].add(Environments.staging);
  featureEnablement[feature].add(Environments.litestaging);
});

// Add feature here to enable it in prod
const featuresDeployedToProd = new Set<Features>([
  Features.catalogForRank,
  Features.panel,
  Features.panel2,
  Features.conjoint,
]);

const disableDeployedToProd = (process.env['NODE_ENV'] ?? '') === 'test';
export const isFeatureEnabled = disableDeployedToProd
  ? (feature: Features) =>
      feature === getCurrentEnv() || (featureEnablement[feature]?.has(getCurrentEnv() as Environments) ?? false)
  : (feature: Features) =>
      featuresDeployedToProd.has(feature) ||
      (featureEnablement[feature]?.has(getCurrentEnv() as Environments) ?? false);
