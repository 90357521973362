import rfdc from 'rfdc';
import { transformToReadable } from '../components/utils';
import { striptagsAndUnescapeText } from '../components/utils/helpers';
import { removeStringPatternFromTheEnd } from '../components/utils/string.utils';

const cloneDeep = rfdc();

class Utils {
  /**
   * Parses an item frontendId to int
   * Gets the frontendId from Max Diff Experiment questions' sets that were created when generating pages for the
   * survey page.
   * This is because Max Diff Experiment questions get duplicated n times (n = number of sets) with a `_[0, 1, ..., n]`
   * postfix added to each frontendId item in each page set in survey page.
   * @param frontendId - int / string: The item frontendId
   * @returns int: The actual item frontendId
   */
  static parseItemFrontendId(frontendId) {
    if (typeof frontendId === 'string') {
      if (frontendId.indexOf('_')) {
        return parseInt(frontendId.split('_')[0], 10);
      }
      return parseInt(frontendId, 10);
    }
    return frontendId;
  }

  static mapJumpOptionsIdsToPositions(jumpOptions, allOptions) {
    return jumpOptions.map((optionId) => (allOptions[optionId] ? allOptions[optionId].position : 'NOT_EXITS'));
  }

  static mapJumpSelectionsIdsToPositions(jumpSelections, allOptions, allRows) {
    return jumpSelections.map((selection) => {
      return {
        option: allOptions[selection.option].position,
        row: allRows[selection.row].position,
      };
    });
  }

  static extendConceptTestLogic(skipLogic = {}, pages = []) {
    const conceptTestsDictionary = {};

    pages.forEach((page) => {
      if (page.conceptTestId) {
        if (!conceptTestsDictionary[page.conceptTestId]) {
          conceptTestsDictionary[page.conceptTestId] = {};
        }
        const itemsIds = conceptTestsDictionary[page.conceptTestId][page.pageId] || [];
        const itemId = page.items.length > 1 ? page.items[1] : page.items[0];
        conceptTestsDictionary[page.conceptTestId][page.pageId] = [...itemsIds, itemId];
      }
    });

    const conceptTestsKeys = Object.keys(conceptTestsDictionary);
    const skipLogicWithConceptTestLogic = cloneDeep(skipLogic);

    for (const conceptTestId of conceptTestsKeys) {
      const conceptTest = conceptTestsDictionary[conceptTestId];
      const ctPagesItems = Object.values(conceptTest);

      if (ctPagesItems.length > 1) {
        const baseCTPageItems = ctPagesItems[0];

        baseCTPageItems.forEach((itemId, itemIndex) => {
          if (skipLogic[conceptTestId] && skipLogic[conceptTestId].logic[itemId]) {
            for (let pageIndex = 1; pageIndex < ctPagesItems.length; pageIndex++) {
              const ctPageItems = ctPagesItems[pageIndex];

              skipLogicWithConceptTestLogic[conceptTestId].logic[ctPageItems[itemIndex]] = cloneDeep(
                skipLogic[conceptTestId].logic[itemId]
              );

              const jumps = skipLogicWithConceptTestLogic[conceptTestId].logic[ctPageItems[itemIndex]].jumps;
              jumps.forEach((jump) => {
                if (jump.skipTo) {
                  const skipToItemIndex = baseCTPageItems.findIndex((baseItemId) => baseItemId === jump.skipTo);
                  jump.skipTo = ctPageItems[skipToItemIndex] ? ctPageItems[skipToItemIndex] : jump.skipTo;
                  jump.sameCT = !!ctPageItems[skipToItemIndex];
                }
              });
            }
          }
        });
      }
    }

    return skipLogicWithConceptTestLogic;
  }

  static isValidRange(start, end, step) {
    return (
      !isNaN(start) &&
      !isNaN(end) &&
      !isNaN(step) &&
      start <= end &&
      step <= end &&
      +parseFloat((end - start) % step) <= 10 &&
      +parseFloat((end - start) % step).toFixed(2) === 0
    );
  }

  static getTransformedPipedText({
    text = '',
    transformedAnswers,
    surveyVariables,
    conceptTitle,
    loopTag,
    isRequired,
    priceTag = null,
  }) {
    let title = removeStringPatternFromTheEnd(text, '<p><br></p>');

    const questionsPipes = Utils.getQuestionsPipes(title);
    questionsPipes.forEach(({ tag, frontendId, rank }) => {
      let answer = transformToReadable(transformedAnswers[frontendId] || []);
      if (rank) {
        answer = transformToReadable(transformedAnswers[`${frontendId}-${rank}`] || []);
      }
      if (answer || answer === 0) {
        title = title.replace(`>${tag}<`, `>${answer}<`);
        title = title.replace(`>﻿${tag}﻿<`, `>${answer}<`); //weird character added by google translation
        title = title.replace(`> ${tag} <`, `>${answer}<`);
      }
    });
    const questionsVariablesPipes = Utils.getQuestionsVariablesPipes(title);
    questionsVariablesPipes.forEach(({ tag, variableId }) => {
      const answer = surveyVariables[variableId]
        ? surveyVariables[variableId].values[surveyVariables[variableId].values.length - 1]
        : '';
      if (answer !== '') {
        title = title.replace(`>${tag}<`, `>${answer}<`);
        title = title.replace(`>﻿${tag}﻿<`, `>${answer}<`); //weird character added by google translation
        title = title.replace(`> ${tag} <`, `>${answer}<`);
      }
    });
    if (conceptTitle) {
      title = title.replace(/\$\{CTID:concept title\}/g, striptagsAndUnescapeText(conceptTitle));
    }
    if (loopTag) {
      title = title.replace(/\$\{SOURCE:Question Loop\}/g, striptagsAndUnescapeText(loopTag));
    }
    if (priceTag) {
      title = title.replace(/\$\{\[Price\]\}/g, striptagsAndUnescapeText(priceTag));
    }

    const endParagraphIndex = title.lastIndexOf('</');
    const lastClosedTag = endParagraphIndex >= 0 ? title.slice(endParagraphIndex) : '';
    if (lastClosedTag && lastClosedTag.match(/<\/(.*)>/g).length === 1) {
      return `${title.slice(0, endParagraphIndex)}` + `${Utils.getRequiredMark(isRequired)}${lastClosedTag}`;
    }

    return `${title}${Utils.getRequiredMark(isRequired)}`;
  }

  static getRequiredMark(isRequired) {
    if (isRequired) {
      return '<span class="isRequired">*</span>';
    }
    return '';
  }

  static getQuestionsPipes(title) {
    const regx = /(?:\${QID:(\d+)-?(\d+)* Answers})/g;
    const questionsPipes = [];
    const cleanTitle = striptagsAndUnescapeText(title);
    let match;
    do {
      match = regx.exec(cleanTitle);
      if (match) {
        const [tag, frontendId, rank] = match;
        questionsPipes.push({ tag, frontendId, rank });
      }
    } while (match);
    return questionsPipes;
  }

  static getQuestionsVariablesPipes(title) {
    const regx = /(?:\${VID:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})+ Variables})+/g;
    const questionsVariables = [];
    const cleanTitle = striptagsAndUnescapeText(title);
    let match;
    do {
      match = regx.exec(cleanTitle);
      if (match) {
        const [tag, variableId] = match;
        questionsVariables.push({ tag, variableId });
      }
    } while (match);
    return questionsVariables;
  }

  static getCatalogTags(title) {
    const regx = /(?:\$\{CID:([^{}]+) - label\})/g;
    const catalogTags = [];
    const cleanTitle = striptagsAndUnescapeText(title);
    let match;
    do {
      match = regx.exec(cleanTitle);
      if (match) {
        const [tag, catalogItemName] = match;
        catalogTags.push({ tag, catalogItemName });
      }
    } while (match);
    return catalogTags;
  }

  static getConceptTitleTags(title) {
    const regx = /(?:\$\{CTID:concept title\})/g;
    const conceptTitleTags = [];
    const cleanTitle = striptagsAndUnescapeText(title);
    let match;
    do {
      match = regx.exec(cleanTitle);
      if (match) {
        const [tag] = match;
        conceptTitleTags.push(tag);
      }
    } while (match);
    return conceptTitleTags;
  }

  static getCatalogUrlAndText(item, translatedCatalog, catalog) {
    const { label, labelCatalogItemId, url, imageCatalogItemId } = item;
    let newLabel, newUrl;

    newLabel = label;
    if (labelCatalogItemId) {
      if (translatedCatalog[labelCatalogItemId] && translatedCatalog[labelCatalogItemId].text) {
        newLabel = translatedCatalog[labelCatalogItemId].text;
      } else if (catalog[labelCatalogItemId] && catalog[labelCatalogItemId].text) {
        newLabel = catalog[labelCatalogItemId].text;
      }
    }

    newUrl = url;
    if (imageCatalogItemId) {
      if (translatedCatalog[imageCatalogItemId] && translatedCatalog[imageCatalogItemId].url) {
        newUrl = translatedCatalog[imageCatalogItemId].url;
      } else if (catalog[imageCatalogItemId] && catalog[imageCatalogItemId].url) {
        newUrl = catalog[imageCatalogItemId].url;
      }
    }

    return { url: newUrl, label: newLabel };
  }
}

export default Utils;
