import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const clipboardPasteReducer = createSlice({
  name: 'clipboardPaste',
  initialState,
  reducers: {
    clearClipboardPaste() {
      return false;
    },
    setClipboardPasteToTrue() {
      return true;
    },
  },
});

export const { clearClipboardPaste, setClipboardPasteToTrue } = clipboardPasteReducer.actions;
const surveyPreviewLoadingReducer = clipboardPasteReducer.reducer;
export default surveyPreviewLoadingReducer;
