// TODO: Check unused reducers
import { combineReducers } from 'redux';

import audienceReducer from './audience';
import itemsReducer from './items';
import optionsReducer from './subItems/options';
import rowsReducer from './subItems/rows';
import translationsReducer from './translations';
import criteriaReducer from './criteria';
import sectionVisibleReducer from './sectionVisible';
import surveyReducer from './survey';
import questionVisibleReducer from './questionVisible';
import pagesReducer from './pages';

const reducers = {
  audience: audienceReducer,
  criteria: criteriaReducer,
  items: itemsReducer,
  options: optionsReducer,
  pages: pagesReducer,
  questionVisible: questionVisibleReducer,
  rows: rowsReducer,
  sectionVisible: sectionVisibleReducer,
  survey: surveyReducer,
  translations: translationsReducer,
};

export default combineReducers(reducers);
