export const SightXFavIcons = [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: 'apple-touch-icon.png?v=3'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: 'favicon-32x32.png?v=3'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: 'favicon-16x16.png?v=3'
  },
  {
    rel: 'mask-icon',
    href: 'safari-pinned-tab.svg?v=3',
    color: '#5bbad5'
  },
  {
    rel: 'shortcut icon',
    href: 'favicon.ico?v=3'
  }
];