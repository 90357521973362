export const capitalizeFirstLetter = (word) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

export const getTextWidth = (txt, font = '14px arial') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return Math.ceil(context.measureText(txt).width);
};

export const insertSpaceBeforeUpperCaseLetters = (text) => {
  return [...text]
    .reduce((result, letter) => {
      if (letter.toUpperCase() === letter) {
        result.push(' ');
      }
      result.push(letter);
      return result;
    }, [])
    .join('');
};

export const getDigitsOnly = (value) => {
  return `${value.replace(/\D/g, '')}`;
};

export const trimNonBreakSpace = (text) => {
  return text.replace(/&nbsp;/g, ' ').trim();
};

export const removeStringPatternFromTheEnd = (baseString, stringPattern) => {
  let resultString = `${baseString}`;
  while (resultString.endsWith(stringPattern)) {
    resultString = resultString.slice(0, resultString.length - stringPattern.length);
  }
  return resultString;
};

export const getNaiveCharactersCountToMaximizeWidth = (text = '', maxWidth, font = '14px arial') => {
  let currentText = text;
  let currentWidth = getTextWidth(currentText, font);
  let naiveCharCount = text.length;

  while (currentWidth > maxWidth) {
    naiveCharCount = (currentText.length * maxWidth) / currentWidth;
    currentText = currentText.substring(0, naiveCharCount);
    currentWidth = getTextWidth(currentText, font);
  }
  return naiveCharCount;
};

export const formatString = (string = '', ...values) => {
  let stringWithValues = string;

  values.forEach((value, index) => {
    stringWithValues = stringWithValues.replace(new RegExp(`{VALUE_TAG_${index}}`, 'g'), value);
  });

  return stringWithValues;
};
