export const USERS_FETCHING = 'USERS_FETCHING';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';

export const COMPANIES_FETCHING = 'COMPANIES_FETCHING';
export const COMPANIES_FETCH_SUCCESS = 'COMPANIES_FETCH_SUCCESS';
export const COMPANIES_FETCH_FAILED = 'COMPANIES_FETCH_FAILED';

export const USERS_IMPERSONATING = 'USERS_IMPERSONATING';
export const USERS_IMPERSONATE_SUCCESS = 'USERS_IMPERSONATE_SUCCESS';
export const USERS_IMPERSONATE_FAILED = 'USERS_IMPERSONATE_FAILED';
export const USERS_BACK_TO_MY_USER = 'USERS_BACK_TO_MY_USER';
export const USERS_UPDATE_REFRESH_TOKEN = 'USERS_UPDATE_REFRESH_TOKEN';
