import React, { ReactNode } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { routerHistory } from './store/rootReducer';
import store from './store';
import Router from './components/router';
import Notifications from './components/common/notifications';
import { FavIcon } from './components/common/favIcon';

class App extends React.Component {
  async componentDidMount(): Promise<void> {
    if (!('scrollBehavior' in document.documentElement.style)) {
      await import('scroll-behavior-polyfill');
    }
  }
  render(): ReactNode {
    return (
      <>
        <FavIcon />
        <Provider store={store} context={ReactReduxContext}>
          <ConnectedRouter history={routerHistory} context={ReactReduxContext}>
            <Router />
          </ConnectedRouter>
          <Notifications />
        </Provider>
      </>
    );
  }
}

export default App;
