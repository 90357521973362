// TODO: Check unused code
import * as ActionTypes from '../../actions/types/audiences';
import { SURVEY_FETCH_REQUEST } from '../../actions/types/activeProject';
import createReducer from '../createReducer';

const initialState = {
  fetching: false,
};

export default createReducer(initialState, {
  [ActionTypes.PROJECT_AUDIENCE_SELECT](state, { payload }) {
    if (payload === null) {
      return {
        fetching: false,
      };
    }
    return state;
  },
  [ActionTypes.PROJECT_AUDIENCE_FETCHING]() {
    return {
      fetching: true,
    };
  },
  [ActionTypes.PROJECT_AUDIENCE_FETCH_FAILED]() {
    return {
      fetching: false,
    };
  },
  [ActionTypes.PROJECT_AUDIENCE_FETCH_SUCCESS](state, action) {
    return {
      fetching: false,
      ...action.response,
      usesAudience: action.payload.usesAudience,
    };
  },
  [SURVEY_FETCH_REQUEST](state) {
    return {
      ...state,
      usesAudience: false,
    };
  },
});
