// TODO: Check unused code
import rfdc from 'rfdc';

import createReducer from '../createReducer';
import * as criteriaActions from '../../actions/types/criteria';
import * as activeProjectActions from '../../actions/types/activeProject';

const cloneDeep = rfdc();

const initialState = {};

const initItem = (state, itemFrontendId) => {
  if (!state[itemFrontendId]) {
    state[itemFrontendId] = {
      variables: [],
      items: [],
    };
  }
};

export default createReducer(initialState, {
  [activeProjectActions.SURVEY_FETCH_SUCCESS](state, action) {
    const { criteria = {} } = action.payload;
    return cloneDeep(criteria);
  },
  // [activeProjectActions.ACTIVE_PROJECT_REMOVE_ITEM](state, action) {
  //   const { frontendId } = action.payload;
  //   const newState = cloneDeep(state);
  //   delete newState[frontendId];
  //   return newState;
  // },
  // [activeProjectActions.ACTIVE_PROJECT_CLONE_ITEM](state, action) {
  //   const { criteria = {} } = action.payload;
  //   const newState = {
  //     ...cloneDeep(state),
  //     ...criteria,
  //   };
  //   return newState;
  // },
  [criteriaActions.CRITERIA_ADD_VARIABLE](state, action) {
    const { itemFrontendId, variable } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].variables.push(variable);
    return newState;
  },
  [criteriaActions.CRITERIA_REPLACE_VARIABLE](state, action) {
    const { itemFrontendId, index, newVariable } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].variables = [
      ...newState[itemFrontendId].variables.slice(0, index),
      newVariable,
      ...newState[itemFrontendId].variables.slice(index + 1),
    ];
    return newState;
  },
  [criteriaActions.CRITERIA_DELETE_VARIABLE](state, action) {
    const { itemFrontendId, index } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].variables = [
      ...newState[itemFrontendId].variables.slice(0, index),
      ...newState[itemFrontendId].variables.slice(index + 1),
    ];
    return newState;
  },
  [criteriaActions.CRITERIA_ADD_CRITERION](state, action) {
    const { itemFrontendId, criterion } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].items.push(criterion);
    return newState;
  },
  [criteriaActions.CRITERIA_UPDATE_CRITERION](state, action) {
    const { itemFrontendId, index, criterion } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].items = [
      ...newState[itemFrontendId].items.slice(0, index),
      {
        ...newState[itemFrontendId].items[index],
        ...criterion,
      },
      ...newState[itemFrontendId].items.slice(index + 1),
    ];
    return newState;
  },
  [criteriaActions.CRITERIA_DELETE_CRITERION](state, action) {
    const { itemFrontendId, index } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].items = [
      ...newState[itemFrontendId].items.slice(0, index),
      ...newState[itemFrontendId].items.slice(index + 1),
    ];
    return newState;
  },
  [criteriaActions.CRITERIA_CLEAR_VARIABLE_MATCHES](state, action) {
    const { itemFrontendId, variableColNumber } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].items.forEach((criterion) => {
      if (criterion.matches) {
        delete criterion.matches[variableColNumber];
      }
    });
    return newState;
  },
  [criteriaActions.CRITERIA_CLEAR_CRITERION_ITEMS](state, action) {
    const { itemFrontendId } = action.payload;
    const newState = cloneDeep(state);
    initItem(newState, itemFrontendId);
    newState[itemFrontendId].items = [];
    return newState;
  },
  [criteriaActions.CRITERIA_DELETE_CRITERIA](state, action) {
    const { itemFrontendId } = action.payload;
    const newState = cloneDeep(state);
    delete newState[itemFrontendId];
    return newState;
  },
});
