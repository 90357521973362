export const SURVEY_ITEMS = {
  page: 'page',
  question: 'question',
  decorator: 'decorator',
};

export const ITEM_TYPES = {
  multiple: 'multiple',
  input: 'input',
  scale: 'scale',
  scaleNumeric: 'scaleNumeric',
  grid: 'grid',
  conjoint: 'conjoint',
  rank: 'rank',
  decorator: 'decorator',
  pageBreak: 'pageBreak',
  promoter: 'promoter',
  constantSum: 'constant-sum',
  date: 'date',
  contactInfo: 'contact-info',
  heatmap: 'heatmap',
  delta: 'delta',
  pageTitle: 'PAGE_TITLE',
  numericMatrix: 'numeric-matrix',
  methodology: 'methodology',
  highlight: 'highlight',
  // Use for Features.conjoint
  conjoint2: 'conjoint2',
  // End of use for Features.conjoint
} as const;

export const ITEM_SUBTYPES = {
  text: 'text',
  number: 'number',
  slider: 'slider',
  icon: 'icon',
  image: 'image',
  info: 'info',
  information: 'information',
  separator: 'separator',
  link: 'link',
  conceptTest: 'concept-test',
  maxDiff: 'max-diff',
  maxDiffExperiment: 'max-diff-experiment',
  nps: 'nps',
  maxDiffAggregated: 'max-diff-aggregated',
  comments: 'comments',
  questionLoop: 'question-loop',
  pastedText: 'pasted-text',
  vanWestendorp: 'van-westendorp',
  gaborGranger: 'gabor-granger',
  keyDriver: 'key-driver',
  cover: 'cover',
};

export const ITEM_ARRAY_TYPES = {
  options: 'options',
  columns: 'columns',
  rows: 'rows',
};

export const TRANSLATE_TYPES = {
  welcomeGreetings: 'welcomeGreetings',
  projectTitle: 'projectTitle',
  endOfSurvey: 'endOfSurvey',
  termsAndConditions: 'termsAndConditions',
  pageTitle: 'pageTitle',
  itemTitle: 'itemTitle',
  itemText: 'itemText',
  optionTitle: 'optionTitle',
  columnTitle: 'columnTitle',
  rowTitle: 'rowTitle',
  leftLabel: 'leftLabel',
  rightLabel: 'rightLabel',
  configLeftLabel: 'configLeftLabel',
  configRightLabel: 'configRightLabel',
  configInstructions: 'configInstructions',
  catalogLabel: 'catalogLabel',
  catalogImage: 'catalogImage',
};

export const CONCEPT_OPTIONS = {
  externalLink: 'externalLink',
  imageUpload: 'imageUpload',
  youtubeVideo: 'youtubeVideo',
  audio: 'audio',
  privateVideo: 'privateVideo',
  text: 'text',
  page: 'page',
};

export const MC_DISPLAY_OPTIONS = {
  list: 'list',
  dropdown: 'dropdown',
  grid: 'grid',
};

export const MC_COLUMNS_OPTIONS = {
  auto: 'auto',
  one: 1,
  two: 2,
  three: 3,
};

export const MC_IMAGE_SIZE_OPTIONS = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export enum ItemImageSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export const NA_COLUMN = 'na';
export const SINGLE_ROW = 'singleRow';

export const PAGINATION_BUTTON_TYPE = {
  DEFAULT: undefined,
  ARROW: 'bigger',
  CHEVRON: 'simple',
};

export const PIPING_DESTINATION = {
  ROWS: 'rows',
  OPTIONS: 'options',
};
