import {
  isMultipleChoice,
  isTextEntry,
  isRank,
  isNumericEntry,
  isMultipleInput,
  sanitizeText,
  striptagsAndUnescapeText,
  isConstantSum,
  isNumericMultiple,
  areFrontendIdsEqual,
} from './helpers';
import Utils from '../../utils/utils';

export const getTranslationOfSelectedLanguage = (state = {}, selectedLanguage) => {
  const translation =
    state.activeProject &&
    state.activeProject.translations &&
    state.activeProject.translations.data &&
    state.activeProject.translations.data[selectedLanguage]
      ? state.activeProject.translations.data[selectedLanguage]
      : {};
  return {
    items: {},
    ...translation,
  };
};

const getValuesFromAnswer = (answer) => {
  if (!answer) {
    return null;
  }

  if (Array.isArray(answer)) {
    const lastIndex = answer.length - 1;
    return answer[lastIndex].values;
  }
  return answer.values;
};

const hasAnswerValues = (answers, frontendId) => {
  return answers[frontendId] && answers[frontendId].values;
};

const hasAnswerValue = (answers, frontendId) => {
  return answers[frontendId] && (answers[frontendId].value || answers[frontendId].value === 0);
};

const transformAnswersForTextNumericEntries = ({ frontendId, question, answers, transformedAnswers }) => {
  if (!hasAnswerValue(answers, frontendId)) {
    return transformedAnswers;
  }

  transformedAnswers[frontendId] = [];

  let value = answers[frontendId].value;
  if (isTextEntry(question)) {
    value = striptagsAndUnescapeText(answers[frontendId].value);
  }

  transformedAnswers[frontendId].push(value);

  return transformedAnswers;
};

const transformAnswersForMultipleChoice = ({
  frontendId,
  question,
  answers,
  options,
  loopingOptionFrontendId,
  catalog,
  translatedCatalog,
  itemsTranslated,
  transformedAnswers,
}) => {
  if (!hasAnswerValues(answers, frontendId)) {
    return transformedAnswers;
  }
  transformedAnswers[frontendId] = [];
  const values = getValuesFromAnswer(answers[frontendId]);
  values.forEach((value) => {
    const optionFrontendId = question.options.find(
      (questionOption) => options[questionOption] && options[questionOption].position === value
    );
    if (
      optionFrontendId !== undefined &&
      optionFrontendId !== loopingOptionFrontendId &&
      loopingOptionFrontendId !== undefined
    ) {
      return;
    }
    const option = options[optionFrontendId];
    // TODO: Check if this is still valid, i think title is no longer being set when the option is translated
    if (itemsTranslated && itemsTranslated[optionFrontendId] && itemsTranslated[optionFrontendId].title) {
      transformedAnswers[frontendId].push(itemsTranslated[optionFrontendId].title);
      return;
    }
    if (option) {
      if (option.isOther) {
        transformedAnswers[frontendId].push(answers[frontendId].other);
        return;
      }
      const { label } = Utils.getCatalogUrlAndText(option, translatedCatalog, catalog);
      transformedAnswers[frontendId].push(label);
    }
  });
  return transformedAnswers;
};

const transformAnswersForConstantSum = ({ frontendId, question, answers, rows = {}, transformedAnswers = [] }) => {
  if (!hasAnswerValues(answers, frontendId)) {
    return transformedAnswers;
  }
  const values = getValuesFromAnswer(answers[frontendId]);
  if (values == null) {
    return transformedAnswers;
  }
  Object.keys(values).forEach((position) => {
    const value = values[position];
    const rowFrontendId = (question.rows || []).find((questionRowFrontendId) => {
      return rows[questionRowFrontendId] && +rows[questionRowFrontendId].position === +position;
    });
    const hasValue = value === 0 || value != null;
    if (!rowFrontendId || !hasValue) {
      return;
    }
    const key = `${frontendId}-${rowFrontendId}`;
    transformedAnswers[key] = [value];
  });
  return transformedAnswers;
};

const transformAnswersForNumericAndTextMultiple = ({
  frontendId,
  question,
  answers,
  rows = {},
  transformedAnswers = [],
}) => {
  const questionRows = question.rows || [];
  if (!hasAnswerValue(answers, frontendId) || questionRows.length === 0) {
    return transformedAnswers;
  }
  let value = answers[frontendId].value;
  if (value !== 0 && value == null) {
    return transformedAnswers;
  }
  questionRows.forEach((questionRowFrontendId) => {
    const row = rows[questionRowFrontendId];
    if (!row || !areFrontendIdsEqual(row.questionFrontendId, frontendId)) {
      return;
    }
    const key = `${row.itemId}-${row.questionFrontendId}`;
    if (isTextEntry(question)) {
      value = striptagsAndUnescapeText(answers[frontendId].value);
    }
    transformedAnswers[key] = [value];
  });
  return transformedAnswers;
};

const transformAnswersForRank = ({
  frontendId,
  question,
  answers,
  options,
  loopingOptionFrontendId,
  catalog,
  translatedCatalog,
  itemsTranslated,
  transformedAnswers,
}) => {
  const values = getValuesFromAnswer(answers[frontendId]);
  if (!values) {
    // when NA is selected
    return;
  }
  transformedAnswers[frontendId] = [];
  values.forEach((value) => {
    const { selection, option: optionSelected } = value;
    const optionFrontendId = question.options.find(
      (optionFrontendId) => options[optionFrontendId] && options[optionFrontendId].position === optionSelected
    );

    if (
      optionFrontendId === undefined ||
      (optionFrontendId !== loopingOptionFrontendId && loopingOptionFrontendId !== undefined)
    ) {
      return;
    }

    const option = options[optionFrontendId];
    // TODO: Check if this is still valid, i think title is no longer being set when the option is translated
    if (itemsTranslated && itemsTranslated[optionFrontendId] && itemsTranslated[optionFrontendId].title) {
      transformedAnswers[frontendId].push(itemsTranslated[optionFrontendId].title);
      transformedAnswers[`${frontendId}-${selection}`] = [itemsTranslated[optionFrontendId].title];
      return;
    }

    const { label } = Utils.getCatalogUrlAndText(option, translatedCatalog, catalog);
    transformedAnswers[frontendId].push(label);
    transformedAnswers[`${frontendId}-${selection}`] = [label];
  });

  return transformedAnswers;
};

export const getTransformedAnswers = ({
  onePage,
  answers,
  options,
  translations,
  loopingOptionFrontendId,
  catalog = {},
  rows = {},
}) => {
  const { catalog: translatedCatalog = {}, items: itemsTranslated = {} } = translations;
  const transformedAnswers = {};
  Object.keys(answers).forEach((frontendId) => {
    const question =
      onePage.find(
        (onePageQuestion) => onePageQuestion.frontendId && areFrontendIdsEqual(onePageQuestion.frontendId, frontendId)
      ) || {};
    if (isMultipleChoice(question)) {
      transformAnswersForMultipleChoice({
        frontendId,
        question,
        answers,
        options,
        loopingOptionFrontendId,
        catalog,
        translatedCatalog,
        itemsTranslated,
        transformedAnswers,
      });
      return;
    }
    if (isRank(question)) {
      transformAnswersForRank({
        frontendId,
        question,
        answers,
        options,
        loopingOptionFrontendId,
        catalog,
        translatedCatalog,
        itemsTranslated,
        transformedAnswers,
      });
      return;
    }
    if (!isMultipleInput(question) && (isTextEntry(question) || isNumericEntry(question))) {
      transformAnswersForTextNumericEntries({
        frontendId,
        question,
        answers,
        transformedAnswers,
      });
    }
    if (isConstantSum(question)) {
      transformAnswersForConstantSum({
        frontendId,
        question,
        answers,
        transformedAnswers,
        rows,
      });
    }
    if (isMultipleInput(question)) {
      transformAnswersForNumericAndTextMultiple({
        frontendId,
        question,
        answers,
        rows,
        transformedAnswers,
      });
    }
  });
  return transformedAnswers;
};

export const transformToReadable = (options = []) => {
  const parsedOptions = options.map((option) => {
    return option === 0 ? option : sanitizeText(option);
  });
  const firstPart = parsedOptions.slice(0, parsedOptions.length - 1);
  const lastPart = parsedOptions[parsedOptions.length - 1];
  if (firstPart.length > 0) {
    return `${firstPart.join(', ')} and ${lastPart}`;
  }
  return lastPart;
};

// TODO: reduce max number of params
// eslint-disable-next-line max-params
export const getLoopedResponsesAndLoopingPageIndex = ({
  currentPageObj,
  responses,
  frontendId,
  pageShownCounters = {},
}) => {
  let loopingPageIndex;
  const pageIsLooped = !!currentPageObj.loopingEnabled;
  if (pageIsLooped) {
    const values = responses[frontendId] || [];
    loopingPageIndex = pageShownCounters[currentPageObj.frontendId] || 0;
    return { value: values[loopingPageIndex] || {}, loopingPageIndex };
  } else {
    return { value: responses[frontendId] || {}, loopingPageIndex };
  }
};

export const getTranslatedRows = ({ rows = [], items: translatedItems = {} }) => {
  return rows.map((row) => {
    const translatedRow = { ...row };
    if (translatedItems && translatedItems[row.frontendId]) {
      translatedRow.label = translatedItems[row.frontendId].title;
    }
    return translatedRow;
  });
};
