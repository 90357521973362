// TODO: check if everything will be used
import striptags from 'striptags';
import dompurify from 'dompurify';
import { decode } from 'he';

import { IPSTACK_API_KEY } from '../../config';

import { ITEM_TYPES, ITEM_SUBTYPES } from '../types/objectTypes';
import Utils from '../../utils/utils';
import { isNull, isUndefined } from 'lodash';

export const RANDOMIZE_BASIC = 'basic';

const loadJSONFromUrl = async (url, callback) => {
  let response;
  try {
    response = await fetch(url, {
      method: 'GET',
    });
  } catch (e) {
    return Promise.reject({ data: e.message });
  }
  if (!response.ok) {
    const json = await response.json();
    return Promise.reject({ status: response.status, data: json });
  }
  let json = null;
  if (response.status === 204) {
    return '';
  }
  try {
    json = await response.json();
  } catch (e) {
    return Promise.reject(e.message);
  }
  if (json.country_code) {
    callback(json.country_code);
  } else {
    callback('US');
  }
};

export const geoIpLookup = (callback) => {
  loadJSONFromUrl(`https://api.ipstack.com/check?access_key=${IPSTACK_API_KEY}`, callback);
};

export const stripTagsFromQuestionsTitles = (pages) => {
  return pages.map((page) => {
    return {
      ...page,
      items: page.items.map((item) => {
        return {
          ...item,
          title: striptagsAndUnescapeText(item.title),
        };
      }),
    };
  });
};

export const processYouTubeURL = (youtubeUrl) => {
  const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
  return regex.exec(youtubeUrl);
};

export const getIconNameForQuestion = (item) => {
  switch (item.type) {
    case ITEM_TYPES.multiple:
      if (item.subtype === ITEM_SUBTYPES.text) {
        return 'sx-multiple-choice';
      }
      return `sx-multiple-choice-${item.subtype}`;
    case ITEM_TYPES.scaleNumeric:
    case ITEM_TYPES.scale:
      return 'sx-sliding-scale';
    case ITEM_TYPES.rank:
      return 'sx-rank-order';
    case ITEM_TYPES.grid:
      if (item.subtype === ITEM_SUBTYPES.nps) {
        return 'sx-promoter-entry';
      }
      if (item.subtype === ITEM_SUBTYPES.maxDiff || item.subtype === ITEM_SUBTYPES.maxDiffExperiment) {
        return 'sx-max-diff';
      }
      return 'sx-grid-rating-scale';
    case ITEM_SUBTYPES.text:
      return 'sx-text-entry';
    case ITEM_TYPES.input:
      if (item.subtype === ITEM_SUBTYPES.text) {
        return 'sx-text-entry';
      }
      return 'sx-numeric-entry';
    case ITEM_TYPES.decorator:
      if (item.subtype === ITEM_SUBTYPES.link) {
        return 'sx-external-link';
      }
      if (item.subtype === ITEM_SUBTYPES.conceptTest) {
        return 'sx-external-link';
      }
      return 'sx-plain-text';

    case ITEM_TYPES.pageBreak:
      return 'sx-section';
    case ITEM_TYPES.promoter:
      return 'sx-promoter-entry';
    case ITEM_TYPES.constantSum:
      return 'sx-constant-sum';
    case ITEM_TYPES.date:
      return 'sx-date';
    case ITEM_TYPES.contactInfo:
      return 'sx-contact-info';
    case 'correlation':
      return 'sx-correlation';
    case 'chi-square':
      return 'sx-significant';
    case 'linear-regression':
      return 'sx-linear';
    case 'anova':
      return 'sx-anova';
    case 'automated-persona':
      return 'sx-table';
    case 'grouping':
      return 'sx-grouping';
    case ITEM_TYPES.conjoint:
      return 'sx-icon-conjoint';
    case ITEM_TYPES.heatmap:
      return 'sx-heatmap';
    default:
      return '';
  }
};

export const getQuestionNameForType = (item) => {
  switch (item.type) {
    case ITEM_TYPES.multiple:
      if (item.subtype === ITEM_SUBTYPES.text || item.subtype === ITEM_SUBTYPES.number) {
        return 'Multiple Choice';
      }
      return 'Multiple Choice Image';
    case ITEM_TYPES.scaleNumeric:
    case ITEM_TYPES.scale:
      return 'Scale';
    case ITEM_TYPES.rank:
      return 'Rank';
    case ITEM_TYPES.grid:
      if (item.subtype === ITEM_SUBTYPES.nps) {
        return 'NPS';
      }
      if (item.subtype === ITEM_SUBTYPES.maxDiff) {
        return 'Max Diff';
      }
      if (item.subtype === ITEM_SUBTYPES.maxDiffExperiment) {
        return 'Max Diff Experiment';
      }
      return 'Grid';
    case ITEM_SUBTYPES.text:
      return 'Text Entry';
    case ITEM_TYPES.input:
      if (item.subtype === ITEM_SUBTYPES.text) {
        return 'Text Entry';
      }
      return 'Numeric Entry';
    case ITEM_TYPES.pageBreak:
      return 'Page';
    case ITEM_TYPES.promoter:
      return 'NPS';
    case ITEM_TYPES.constantSum:
      return 'Constant Sum';
    case ITEM_TYPES.date:
      return 'Date';
    case ITEM_TYPES.contactInfo:
      return 'Contact Info';
    case ITEM_TYPES.decorator:
      if (item.subtype === ITEM_SUBTYPES.info) {
        return 'Text Block';
      }
      return 'Decorator';
    default:
      return '';
  }
};

export const buildGroupedConceptTests = (pages) => {
  const groupedConceptTests = {};
  pages.forEach((page) => {
    if (page.items[0] && page.items[0].subtype === 'concept-test') {
      if (!groupedConceptTests[page.items[0].conceptTestId]) {
        groupedConceptTests[page.items[0].conceptTestId] = [];
      }
      groupedConceptTests[page.items[0].conceptTestId].push(page);
    }
  });
  return groupedConceptTests;
};

export const buildConceptTests = (groupedConceptTests) => {
  const conceptTests = [];
  let currentConceptTest;
  Object.keys(groupedConceptTests).forEach((conceptTest) => {
    currentConceptTest = {
      thumbnails: [],
    };
    groupedConceptTests[conceptTest].forEach((conceptPage) => {
      currentConceptTest.thumbnails.push(
        Utils.getThumbnailForQuestion(conceptPage.items[0], conceptPage.items[0].conceptTestId)
      );
      currentConceptTest.title = conceptPage.items[0].conceptTitle;
      currentConceptTest.randomizeItems = conceptPage.items[0].randomizeItems;
      currentConceptTest.description = conceptPage.items[0].title;
      currentConceptTest.id = conceptPage.items[0].conceptTestId;
    });
    conceptTests.push(currentConceptTest);
  });
  return conceptTests;
};

export const isTextBlockItem = (item) =>
  item && item.type === ITEM_TYPES.decorator && item.subtype === ITEM_SUBTYPES.info;
export const isConjointExperiment = (item = {}) => item && item.type === ITEM_TYPES.conjoint;

export const isConjoint2Experiment = (item = {}) => item && item.type === ITEM_TYPES.conjoint2;

export const isConceptTest = (item = {}) => {
  const { type, subtype } = item;
  return type === ITEM_TYPES.decorator && subtype === ITEM_SUBTYPES.conceptTest;
};

export const isQuestionLoop = (item = {}) => {
  const { type, subtype } = item;
  return type === ITEM_TYPES.decorator && subtype === ITEM_SUBTYPES.questionLoop;
};

export const sanitizeText = (text) => {
  return dompurify.sanitize(text, { ALLOWED_TAGS: ['em', 'u', 'strong', 's'] });
};

export const striptagsAndUnescapeText = (text, allowedTags) => decode(striptags(text, allowedTags));

export const getErrorMessage = (error) => (typeof error === 'object' ? error.message || '' : error);

export const hasOptionGroupingRules = (item = {}) => item && item.config && item.config.optionGroupingRules;

export const companyIdFromUser = (state) => {
  if (state.security.impersonate) {
    const { user } = state.security.impersonate;
    return user.companyId;
  }
  const userState = (state.security && state.security.account) || {};

  return userState.companyId;
};

export const isMultipleChoice = (item = {}) => item.type === ITEM_TYPES.multiple;
export const isGrid = (item = {}) => item.type === ITEM_TYPES.grid && item.subtype === undefined;
export const isNPS = (item = {}) => item.type === ITEM_TYPES.grid && item.subtype === ITEM_SUBTYPES.nps;
export const isConstantSum = (item = {}) => item.type === ITEM_TYPES.constantSum;
export const isRank = (item = {}) => item.type === ITEM_TYPES.rank;

export const isPageLooped = (page = {}) => page && page.config && page.config.loopingEnabled;
export const isHeatmap = (item = {}) => item && item.type === ITEM_TYPES.heatmap;
export const isCover = (item = {}) =>
  item && item.type === ITEM_TYPES.decorator && item.subtype === ITEM_SUBTYPES.cover;
export const isLink = (item = {}) => item && item.type === ITEM_TYPES.decorator && item.subtype === ITEM_SUBTYPES.link;
export const isMaxDiffBasic = (item = {}) =>
  item && item.type === ITEM_TYPES.grid && item.subtype === ITEM_SUBTYPES.maxDiff;
export const isMaxDiffExperiment = (item = {}) =>
  item && item.type === ITEM_TYPES.grid && item.subtype === ITEM_SUBTYPES.maxDiffExperiment;

export const isInsideCT = (item = {}) => item.parentId || item.parentId === 0;
export const isDecorator = (item = {}) => item.type === ITEM_TYPES.decorator;
export const isPageTitle = (item = {}) => item.type === ITEM_TYPES.pageTitle;
export const isContactInfo = (item = {}) => item.type === ITEM_TYPES.contactInfo;
export const isScale = (item = {}) => item.type === ITEM_TYPES.scale;
export const isTextEntry = (item = {}) => item.type === ITEM_TYPES.input && item.subtype === ITEM_SUBTYPES.text;
export const isNumericEntry = (item = {}) => item.type === ITEM_TYPES.input && item.subtype === ITEM_SUBTYPES.number;
export const isMultipleInput = (item = {}) =>
  item.type === ITEM_TYPES.input && item.config && item.config.multipleInputs;
export const isSimpleInput = (item = {}) =>
  item.type === ITEM_TYPES.input && item.config && !item.config.multipleInputs;
export const isNumericMultiple = (item = {}) => isNumericEntry(item) && isMultipleInput(item);
export const isNumericMatrix = (item = {}) => item.type === ITEM_TYPES.numericMatrix;
export const isDelta = (item = {}) => item.type === ITEM_TYPES.delta;
export const isDeltaConstantSum = (item = {}) =>
  item.type === ITEM_TYPES.delta && item.subtype === ITEM_TYPES.constantSum;
export const isDeltaInput = (item = {}) => item.type === ITEM_TYPES.delta && item.subtype === ITEM_SUBTYPES.number;
export const hasQuestionPerRow = (item = {}) => isMultipleInput(item) || isDeltaInput(item);

export const getDisplayedRowId = (item, itemFrontendId) => {
  if (item.config && item.config.rowPerPage) {
    return `${itemFrontendId}_${item.showOnlyRow}`;
  }
  return `${itemFrontendId}`;
};

export const areFrontendIdsEqual = (frontEndId1, frontEndId2) =>
  !!frontEndId1 && !!frontEndId2 && `${frontEndId1}` === `${frontEndId2}`;

export const isNullOrUndefinedOrNaN = (value) => {
  return isNull(value) || isNaN(value) || isUndefined(value);
};

export const cleanFrontendId = (frontEndId) => {
  return `${frontEndId}`.split(/(::|_)/g)[0] || '';
};
