import * as Sentry from '@sentry/browser';

import { isOnlyProduction, isDev } from '../utils/platform';
import { FRONTEND_COMMIT, ENV } from '../config';

const UNKNOWN_TAG = 'UNKNOWN';

export const loadSentry = () => {
  if (isOnlyProduction()) {
    Sentry.init({
      dsn: 'https://7c371c7efabf4420a13ecc8d41efdaf0@o325540.ingest.sentry.io/1541374',
      release: FRONTEND_COMMIT,
      environment: ENV,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
    });
  }
};
const generateZone = (store) => {
  const state = store.getState();
  const { router = {} } = state;
  const { location = {} } = router;
  if (location === null) {
    return UNKNOWN_TAG;
  }
  let { pathname = '' } = location;
  if (pathname === '/') {
    return 'PROJECTS_DASHBOARD';
  }
  pathname = pathname.indexOf('/') === 0 ? pathname.substr(1) : pathname;
  const pathParts = pathname.split('/');
  if (pathParts.length === 0) {
    return UNKNOWN_TAG;
  }
  if (pathParts.length === 1) {
    return pathParts[0].toString().toUpperCase();
  }
  switch (pathParts[0]) {
    case 'project': {
      let projectZone = pathParts[2];
      if (!projectZone) {
        projectZone = UNKNOWN_TAG;
      }
      projectZone = projectZone.toUpperCase();
      return `PROJECT_${projectZone}`;
    }
    case 'analysis': {
      let projectZone = pathParts[2];
      if (!projectZone) {
        projectZone = UNKNOWN_TAG;
      }
      projectZone = projectZone.toUpperCase();
      return `ANALYSIS_${projectZone}`;
    }
    case 'recipientList': {
      return 'RECIPIENTLIST_RECIPIENT';
    }
    case 'capture': {
      return 'SURVEY';
    }
    case 'folder': {
      switch (pathParts[1]) {
        case 'f00000000000000000000003':
          return 'FOLDER_ARCHIVE';
        case 'f00000000000000000000002':
          return 'FOLDER_RECYCLEBIN';
        default:
          return 'PROJECTS_DASHBOARD';
      }
    }
    default:
      return UNKNOWN_TAG;
  }
};

const generateCaseTitle = (store, messageForSentry) => {
  let title = messageForSentry.title;

  if (messageForSentry.status) {
    title = `${title} ${messageForSentry.status}`;
  }
  title = `${title} ${generateZone(store)}`;
  return title;
};

const getUserData = (store) => {
  let email, id, username;
  let user = { email: '', id: '', name: '' };
  const { security: { account = {}, impersonate = {} } = {} } = store.getState();
  if (impersonate && impersonate.user) {
    user = {
      email: impersonate.user.email,
      name: impersonate.user.name,
      id: impersonate.user.id,
    };
  }

  email = user.email ? `${user.email}:${account.email}` : account.email;
  id = user.id ? `${user.id}:${account.id}` : account.id;
  username = user.name ? `${user.name}:${account.name}` : account.name;

  return { email, id, username };
};

export const reportErrorToSentry = (error, store) => {
  if (isDev()) {
    return;
  }
  const codesToReport = [400, 422, 500];
  const { status, messageForSentry } = error;
  if (
    status === 422 &&
    messageForSentry.textMessage &&
    JSON.parse(messageForSentry.textMessage).message === 'NumberOfSets cannot exceed possible card arrangements'
  ) {
    return;
  }
  if (messageForSentry && (status === undefined || codesToReport.includes(status))) {
    const { email, id, username } = getUserData(store);
    Sentry.configureScope((scope) => {
      scope.setUser({ email, id, username });
      scope.setTag('type', 'api-call');
      Object.keys(messageForSentry).forEach((key) => {
        scope.setExtra(key, messageForSentry[key]);
      });
    });
    const message = generateCaseTitle(store, error.messageForSentry);
    Sentry.captureMessage(message);
  }
};
