import { connect } from 'react-redux';
import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import Icon, { ExclamationOutlined } from '@ant-design/icons';

import { ReactComponent as FlagIconSVG } from '../../../assets/sx-icons/svg/icon-flag.svg';
import { ReactComponent as CheckIconSVG } from '../../../assets/sx-icons/svg/icon-check.svg';
import { ReactComponent as CrossIconSVG } from '../../../assets/sx-icons/svg/icon-cross.svg';

import { disposeNotification, showNotification } from '../../../store/actions/notifications';

import { isMobileOrTablet } from '../../../utils/platform';
import { capitalizeFirstLetter } from '../../../components/utils/string.utils';

import style, { defaultColors } from './notificationsStyle';
import './notifications.scss';

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: isMobileOrTablet() ? 'bc' : 'tc',
    };
    this.ref = React.createRef();
  }
  componentDidUpdate() {
    if (this.props.newNotification) {
      let notification = this.props.newNotification;
      const { position } = this.state;
      if (!notification.position) {
        notification = { ...notification, position };
      }
      notification.message = this.getMessageWithIcon(notification.message, notification.level);
      this.ref.current.addNotification(notification);
      this.props.disposeNotification();
    }
  }
  renderIcon(level) {
    switch (level) {
      case 'success':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.success.iconShadow }}>
            <Icon component={CheckIconSVG} style={{ backgroundColor: defaultColors.success.hex }} />
          </div>
        );
      case 'warning':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.warning.iconShadow }}>
            <ExclamationOutlined style={{ backgroundColor: defaultColors.warning.hex }} />
          </div>
        );
      case 'info':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.info.iconShadow }}>
            <Icon component={FlagIconSVG} style={{ backgroundColor: defaultColors.info.hex }} />
          </div>
        );
      case 'error':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.error.iconShadow }}>
            <Icon component={CrossIconSVG} style={{ backgroundColor: defaultColors.error.hex }} />
          </div>
        );
      default:
        return null;
    }
  }
  getMessageWithIcon(msg, level) {
    return (
      <div>
        {this.renderIcon(level)}
        <div className="messageContainer">
          <span className="level">{capitalizeFirstLetter(level)}</span> {msg}
        </div>
        <div className="dissmissButton">
          <Icon component={CrossIconSVG} />
        </div>
      </div>
    );
  }
  render() {
    return <NotificationSystem ref={this.ref} style={style} />;
  }
}

function mapStateToProps(state) {
  const { notification } = state;
  return { newNotification: notification.newNotification };
}

export default connect(mapStateToProps, {
  disposeNotification,
  showNotification,
})(Notifications);
