import { SHOW_NOTIFICATION, DISPOSE_NOTIFICATION } from '../../actions/types/notifications';
import createReducer from '../createReducer';

const initialState = {
  newNotification: null,
};

export default createReducer(initialState, {
  [SHOW_NOTIFICATION](state, action) {
    const newState = { ...state };
    newState.newNotification = action.payload;
    return newState;
  },
  [DISPOSE_NOTIFICATION](state) {
    const newState = { ...state };
    newState.newNotification = null;
    return newState;
  },
});
