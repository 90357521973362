export const SURVEY_FETCH_REQUEST = 'SURVEY_FETCH_REQUEST';
export const SURVEY_FETCH_SUCCESS = 'SURVEY_FETCH_SUCCESS';
export const SURVEY_FETCH_FAILED = 'SURVEY_FETCH_FAILED';

export const ACTIVE_PROJECT_TRANSLATION_FETCH_ONE = 'ACTIVE_PROJECT_TRANSLATION_FETCH_ONE';
export const ACTIVE_PROJECT_TRANSLATION_FETCH_ONE_SUCCESS = 'ACTIVE_PROJECT_TRANSLATION_FETCH_ONE_SUCCESS';
export const ACTIVE_PROJECT_TRANSLATION_FETCH_ONE_FAILED = 'ACTIVE_PROJECT_TRANSLATION_FETCH_ONE_FAILED';

export const SURVEY_FETCH_NEXT_PAGE_REQUEST = 'SURVEY_FETCH_NEXT_PAGE_REQUEST';
export const SURVEY_FETCH_NEXT_PAGE_SUCCESS = 'SURVEY_FETCH_NEXT_PAGE_SUCCESS';
export const SURVEY_FETCH_NEXT_PAGE_FAILED = 'SURVEY_FETCH_NEXT_PAGE_FAILED';

export const ACTIVE_PROJECT_CHANGE_LANGUAGE_TO_TRANSLATE = 'ACTIVE_PROJECT_CHANGE_LANGUAGE_TO_TRANSLATE';

export const HIDE_SECTION = 'HIDE_SECTION';
export const SHOW_SECTION = 'SHOW_SECTION';
export const CLEAR_QUESTION_VISIBLE = 'CLEAR_QUESTION_VISIBLE';

export const SCREEN_OUT_SURVEY = 'SCREEN_OUT_SURVEY';
export const SURVEY_PREVIOUS_PAGE = 'SURVEY_PREVIOUS_PAGE';

export const SET_CAMPAIGN_HAS_ERRORS = 'SET_CAMPAIGN_HAS_ERRORS';
