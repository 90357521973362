const {
  REACT_APP_DOMAIN,
  REACT_APP_PROTOCOL,
  REACT_APP_FRONTEND_COMMIT,
  REACT_APP_IPSTACK_API_KEY = '4901dfb3fddd30cd5bc416d85c0fbd8b',
  REACT_APP_ENV = 'development',
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_CLIENT,
} = process.env;

//in case older browsers like ie
const urlSearchParams = URLSearchParams ? new URLSearchParams(window.location.search) : { keys: () => [] };
const params = new Set(urlSearchParams.keys());

const trim = (value: string | undefined) => (value || '').trim();

export const ENV = trim(REACT_APP_ENV);
export const DOMAIN = trim(REACT_APP_DOMAIN);
export const PROTOCOL = trim(REACT_APP_PROTOCOL);
export const FRONTEND_COMMIT = trim(REACT_APP_FRONTEND_COMMIT);
export const IPSTACK_API_KEY = trim(REACT_APP_IPSTACK_API_KEY);
export const RECAPTCHA_SITE_KEY = trim(REACT_APP_RECAPTCHA_SITE_KEY);
export const API_DOMAIN = trim(REACT_APP_DOMAIN);
export const API_PROTOCOL = trim(REACT_APP_PROTOCOL);

export const showQuestionNumberForBuild = params.has('showQuestionNumberForBuild');

export const CLIENT = REACT_APP_CLIENT || 'sightx';
export const getCurrentEnv = () => {
  return ENV;
};
