import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AlignmentOptions = 'left' | 'center' | 'right';

export enum LogoSize {
  small = 'sm',
  medium = 'med',
  large = 'lg',
}

type SizeOptions = keyof typeof LogoSize;

export interface ICustomizationQuestion {
  font: string;
  color: string;
  align: AlignmentOptions;
}

export interface ICustomizationAnswer {
  font: string;
  color: string;
}

export interface ICustomizationLogo {
  color?: string;
  url?: string;
  align: AlignmentOptions;
  size: SizeOptions;
}

export interface IBackgroundImage {
  opacity: number;
  fixed: boolean;
  tiled: boolean;
  url?: string;
}

export interface ICustomizationInitialState {
  progressBar: Record<string, unknown>;
  nextPreviousButton: Record<string, unknown>;
  backgroundImage: IBackgroundImage;
  defaultLanguage: string;
  question: ICustomizationQuestion;
  answer: ICustomizationAnswer;
  foregroundColor: string;
  backgroundColor?: string;
  logo: ICustomizationLogo;
}

const initialState: ICustomizationInitialState = {
  progressBar: {},
  nextPreviousButton: {},
  backgroundImage: {
    opacity: 100,
    fixed: false,
    tiled: false,
    url: '',
  },
  defaultLanguage: 'en',
  question: {
    font: 'Source Sans Pro',
    color: '#000',
    align: 'left',
  },
  answer: {
    font: 'Source Sans Pro',
    color: '#000',
  },
  foregroundColor: '#00b3be',
  backgroundColor: '#fff',
  logo: {
    color: '#fff',
    url: '',
    align: 'left',
    size: 'small',
  },
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    setCustomization(state, action: PayloadAction<ICustomizationInitialState>) {
      state = { ...state, ...action.payload };
      return state;
    },
    changeQuestionFont(state, action: PayloadAction<string>) {
      const font = action.payload;
      state.question.font = font;
    },
    changeAnswersFont(state, action: PayloadAction<string>) {
      const font = action.payload;
      state.answer.font = font;
    },
    changeQuestionColor(state, action: PayloadAction<string>) {
      const color = action.payload;
      state.question.color = color;
    },
    changeAnswersColor(state, action: PayloadAction<string>) {
      const color = action.payload;
      state.answer.color = color;
    },
    changePrimaryColor(state, action: PayloadAction<string>) {
      const color = action.payload;
      state.foregroundColor = color;
    },
    changeBackgroundColor(state, action: PayloadAction<string>) {
      const color = action.payload;
      state.backgroundColor = color;
    },
    changeLogoBackgroundColor(state, action: PayloadAction<string>) {
      const color = action.payload;
      state.logo.color = color;
    },
    toggleBackgroundImageFixed(state) {
      state.backgroundImage.fixed = !state.backgroundImage.fixed;
    },
    toggleBackgroundImageTiled(state) {
      state.backgroundImage.tiled = !state.backgroundImage.tiled;
    },
    setProjectLogo(state, action: PayloadAction<string>) {
      const url = action.payload;
      state.logo.url = url;
    },
    alignProjectLogo(state, action: PayloadAction<AlignmentOptions>) {
      const align = action.payload;
      state.logo.align = align;
    },
    alignQuestionsText(state, action: PayloadAction<AlignmentOptions>) {
      const align = action.payload;
      state.question.align = align;
    },
    changeProjectImageSize(state, action: PayloadAction<SizeOptions>) {
      const size = action.payload;
      state.logo.size = size;
    },
    setProjectBackgroundImage(state, action: PayloadAction<string>) {
      const url = action.payload;
      state.backgroundImage = {
        fixed: true,
        url,
        opacity: 100,
        tiled: false,
      };
    },
    removeBackgroundImage(state) {
      state.backgroundImage.url = undefined;
      state.backgroundColor = undefined;
    },
    setProjectBackgroundImageOpacity(state, action: PayloadAction<number>) {
      const opacity = action.payload;
      state.backgroundImage.opacity = opacity;
    },
    removeProjectLogo(state) {
      state.logo.url = undefined;
      state.logo.color = undefined;
    },
  },
});

export const {
  changeQuestionFont,
  changeAnswersFont,
  setProjectLogo,
  changeAnswersColor,
  changeBackgroundColor,
  changeLogoBackgroundColor,
  changePrimaryColor,
  changeProjectImageSize,
  changeQuestionColor,
  removeBackgroundImage,
  alignProjectLogo,
  alignQuestionsText,
  toggleBackgroundImageFixed,
  toggleBackgroundImageTiled,
  removeProjectLogo,
  setProjectBackgroundImage,
  setProjectBackgroundImageOpacity,
  setCustomization,
} = customizationSlice.actions;
export default customizationSlice.reducer;
