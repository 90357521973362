import { isDebugModeEnabled } from './queryParameters';

export const overwriteConsoleLog = (force = false) => {
  if (force || !isDebugModeEnabled) {
    /* eslint-disable no-global-assign, no-native-reassign, @typescript-eslint/no-empty-function */
    console = {
      log: () => {},
      warn: () => {},
      info: () => {},
      debug: () => {},
      error: () => {},
    } as Console;
    /* eslint-enable no-global-assign, no-native-reassign, @typescript-eslint/no-empty-function */
  }
};
