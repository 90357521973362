export const initialState = {
  fetching: false,
  selectedLanguage: undefined,
  data: {
    en: {
      catalog: {},
      configs: {},
      items: {},
      language: 'en',
      uiTags: {
        addressLineLabel: 'Address Line',
        agree: 'Agree',
        answersErrorMessage: 'Error: Please check your answers for more info',
        builtWith: 'Built with',
        cancel: 'Cancel',
        clickToCloseLink: 'Close',
        clickToDisplayLink: 'Continue',
        conceptTestLinkLabel: 'Please click the video below to begin. Make sure your volume is turned up.',
        conceptTestLinkLabelNoAudio: 'Please click the video below to begin.',
        conjointNoneOfTheseLabel: 'I would not purchase any of these products',
        constantSumTotalLabel: 'Total',
        constantSumValueInputPlaceholder: 'Value',
        countryLabel: 'Country',
        dayLabel: 'Day',
        delete: 'Delete',
        disagree: 'Disagree',
        disqualifiedMessage: 'You are currently not a good fit for this survey.\nThank you so much for your time.',
        dropdownSelectionLabel: 'Select an option',
        emailLabel: 'Email',
        eraseLabel: 'Erase',
        firstNameLabel: 'First Name',
        for: '{VALUE_TAG_0} for {VALUE_TAG_1}',
        genericErrorMessage: 'An error occurred, please try again. If the error persists, please contact us.',
        genericThankYouMessageLabel:
          'Thank you for completing this survey.\nClick on the button below to submit your responses.',
        greaterThan: 'Should be greater than or equal to {VALUE_TAG_0}',
        heatmapCommentsPlaceholder: 'Please explain your reaction',
        heatmapDeleteReaction: 'Delete Reaction',
        heatmapMinClicks: 'The minimum number of clicks required is {VALUE_TAG_0}',
        heatmapModalTitle: 'Select an option to provide feedback',
        inputPlaceholder: 'Your answer here',
        inSet: '{VALUE_TAG_0} on set {VALUE_TAG_1}',
        inSets: '{VALUE_TAG_0} on sets {VALUE_TAG_1}',
        invalidDate: 'Please select a valid date',
        invalidEmail: '{VALUE_TAG_0} is not a valid email address',
        invalidPhoneNumber: '{VALUE_TAG_0} is not a valid phone number',
        invalidValue: 'Invalid value for {VALUE_TAG_0}',
        invalidZipCode: '{VALUE_TAG_0} is not a valid zip code',
        language: 'en',
        lastNameLabel: 'Last Name',
        lessThan: 'Should be less than or equal to {VALUE_TAG_0}',
        loadingLabel: 'Loading',
        monthLabel: 'Month',
        mustSumToRange: 'All values must sum up to a value between {VALUE_TAG_0} and {VALUE_TAG_1}',
        mustSumToTotal: 'All values must sum up to {VALUE_TAG_0}',
        next: 'Next',
        nextPage: 'Next Page',
        noneLabel: 'None',
        npsCommentsPlaceholder: 'Please explain your score',
        numericMatrixColumnTotal: 'Column total',
        numericMatrixError: 'Total',
        numericMatrixInputPlaceholder: 'Value',
        numericMatrixRequiredTotal: 'Required total',
        numericMatrixRowTotal: 'Row total',
        okLabel: 'OK',
        optionGreaterThan: '{VALUE_TAG_0} must be greater than {VALUE_TAG_1}',
        optionGreaterThanOrEqualTo: '{VALUE_TAG_0} must be greater than or equal to {VALUE_TAG_1}',
        optionLessThan: '{VALUE_TAG_0} must be less than {VALUE_TAG_1}',
        optionLessThanOrEqualTo: '{VALUE_TAG_0} must be less than or equal to {VALUE_TAG_1}',
        overQuotaMessage: 'We’ve met our limit of responses for this survey. Thank you so much for your time',
        phoneLabel: 'Phone',
        prev: 'Previous',
        prevPage: 'Previous Page',
        promptForAddress: 'Please enter the address',
        promptForAnswers: 'Error: Please answer the survey',
        promptForComments: 'Include an explanation of your choice.',
        promptForCountry: 'Please choose a country',
        promptForDate: 'Please select a date',
        promptForEmail: 'Please enter the email address',
        promptForFirstName: 'Please enter the first name',
        promptForLastName: 'Please enter the last name',
        promptForNPSComments: 'Please explain your score',
        promptForOption: 'Please choose an option',
        promptForOptionPerRow: 'Please choose an option for every row',
        promptForPhoneNumber: 'Please enter the phone number',
        promptForRankAllOptions: 'Please rank all options',
        promptForRankOptions: 'Please rank exactly {VALUE_TAG_0} options',
        promptForRankOptionsInOrder: 'Ranking needs to be in sequential order starting from 1',
        promptForState: 'Please choose a state',
        promptForUniqueOptionPerRow: 'Please choose a different option for every row',
        promptForValidDecimal: 'Should be a valid decimal',
        promptForValidNumber: 'Should be a valid number',
        promptForZipCode: 'Please enter the zip code',
        rankDesktopBottomPlaceholder: 'Drag and drop options here, or click on options to rank them.',
        rankDesktopTopPlaceholder: 'Drag and drop options here, or click on options to rank them.',
        rankInfoLabel: 'Remember to either drag and drop or click to add options',
        rankMobileBottomPlaceholder: 'Please select this number of options:',
        rankMobilePlaceholder:
          'Tap options to rank them, and drag options to reorder. Tap ranked options to remove them.',
        required: 'Required',
        requiredMaxOptions: 'Maximum number of options required: {VALUE_TAG_0}',
        requiredMinCharacters: 'Longer answer required',
        requiredMinOptions: 'Minimum number of options required: {VALUE_TAG_0}',
        requiredMinOptionsPerRow: 'Minimum number of options per row required: {VALUE_TAG_0}',
        requiredOptionInSet: 'Please choose {VALUE_TAG_0} option',
        requiredOptionPerColumn: 'Please choose one option in each column.',
        requiredOptions: 'Number of options required: {VALUE_TAG_0}',
        requiredOptionsInSet: 'Please choose {VALUE_TAG_0} options',
        requiredOtherOption: 'Other option is required',
        requiredRangeOptionsPerRow: 'Between {VALUE_TAG_0} and {VALUE_TAG_1} options per row required:',
        resetLabel: 'Reset',
        save: 'Save',
        stateLabel: 'State',
        submit: 'Submit',
        surveyProgressBarLabel: 'completed',
        termsAndConditionsLabel: 'I agree with Terms And Conditions',
        termsAndConditionsModalTitle: 'Terms & Conditions',
        textHighLightTitle: 'Highlight some text',
        textHighlightPlaceHolderDesktop: 'Select a category, and click on or drag over the text to highlight it.',
        textHighlightPlaceHolderMobile: 'Please select the text first, and then select the category.',
        unsubscribedError: 'Service unavailable. Please try again later.',
        unsubscribeDescription: 'Are you sure you want to unsubscribe from our mailing list?',
        unsubscribedMessage: 'You have been successfully unsubscribed from our mailing list.',
        unsubscribeLabel: 'Unsubscribe',
        weAreSorryMessage: "We're sorry.",
        yearLabel: 'Year',
        zipCodeLabel: 'Zip Code',
      },
      welcome: {},
    },
  },
};
