import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const surveyPreviewLoading = createSlice({
  name: 'surveyPreviewLoading',
  initialState,
  reducers: {
    setPreviewLoading(state, action) {
      return action.payload;
    },
  },
});

export const { setPreviewLoading } = surveyPreviewLoading.actions;
const surveyPreviewLoadingReducer = surveyPreviewLoading.reducer;
export default surveyPreviewLoadingReducer;
