import { createSlice } from '@reduxjs/toolkit';
interface ICatalog {
  items: {
    [_id: string]: ICatalogItem;
  };
  nameIndexedItems: {
    [_id: string]: INameIndexedCatalogItem;
  };
}
interface ICatalogItem {
  name: string;
  url?: string;
  text?: string;
}
interface INameIndexedCatalogItem {
  id: string;
  name: string;
  url?: string;
  text?: string;
}
const initialState: ICatalog = {
  items: {},
  nameIndexedItems: {},
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalog(state, action) {
      const { payload: catalog } = action;
      state.items = { ...catalog };
      const nameIndexedItems = {};
      for (const catalogItemId of Object.keys(catalog)) {
        const catalogItem = catalog[catalogItemId];
        nameIndexedItems[catalogItem.name] = {
          ...catalogItem,
          id: catalogItemId,
        };
      }
      state.nameIndexedItems = nameIndexedItems;
      return state;
    },
  },
});

export const { setCatalog } = catalogSlice.actions;
const catalogReducer = catalogSlice.reducer;
export default catalogReducer;
