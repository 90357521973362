import React, { Component, ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  className?: string;
}

class Loader extends Component<Props> {
  render(): ReactNode {
    return (
      <div className={this.props.className}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }
}

export default Loader;
