import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';

import legacyReducers from './reducers';
import catalogReducer from './slices/catalog';
import customizationReducer from './slices/customization/customization';
import pageShownCountersReducer from './slices/pageShownCounters/pageShownCounters';
import surveyVariablesReducer from './slices/surveyVariables/surveyVariables';
import projectVariablesReducer from './slices/projectVariables/projectVariables';
import endOfSurveyPageReducer from './slices/endOfSurveyPage/endOfSurveyPage';
import displayedSubItemsReducer from './slices/displayedSubItems';
import conceptTestReducer from './slices/conceptTest';
import visualizationTypesReducer from './slices/visualizationTypes';
import surveyPreviewLoadingReducer from './slices/surveyPreviewLoading';
import clipboardPasteReducer from './slices/clipboardPaste';
import { responsesReducer } from './slices/responses';
import { methodologyReducer } from './slices/methodology';

export const routerHistory = createBrowserHistory();

export const rootReducer = combineReducers({
  router: connectRouter(routerHistory),
  catalog: catalogReducer,
  customization: customizationReducer,
  displayedSubItems: displayedSubItemsReducer,
  endOfSurveyPage: endOfSurveyPageReducer,
  pageShownCounters: pageShownCountersReducer,
  projectVariables: projectVariablesReducer,
  surveyVariables: surveyVariablesReducer,
  conceptTest: conceptTestReducer,
  surveyPreviewLoading: surveyPreviewLoadingReducer,
  visualizationTypes: visualizationTypesReducer,
  clipboardPaste: clipboardPasteReducer,
  responses: responsesReducer,
  methodology: methodologyReducer,
  ...legacyReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  state: RootState;
}
