// TODO: Check unused code
import rfdc from 'rfdc';

import createReducer from '../createReducer';
import * as surveyActions from '../../actions/types/survey';
import * as actionTypes from '../../actions/types/activeProject';

const cloneDeep = rfdc();

const initialState = {
  breadcrumbs: [],
  campaignLanguage: null,
  currentPage: -1,
  enableNextButton: false,
  isPreview: false,
  isSurveyOver: false,
  isTest: false,
  loading: false,
  pageToShow: 0,
  projectId: null,
  projectTitle: '',
  questionFocused: null,
  recipientVariables: {},
  showConceptTest: false,
  showHeatmap: false,
  totalPages: 0,
  userData: null,
  dqa: false,
};

// const shuffle = array => {
//   for (let i = array.length - 1; i > 0; i--) {
//     let j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
// };

export default createReducer(initialState, {
  [actionTypes.SURVEY_FETCH_REQUEST]() {
    return {
      ...initialState,
      loading: true,
    };
  },
  [actionTypes.SURVEY_FETCH_SUCCESS](state, action) {
    const newState = cloneDeep(state);
    const {
      project: {
        customization = {},
        campaignLanguage,
        totalPages,
        userData,
        projectId,
        projectTitle,
        isPreview,
        isTest,
        currentPage,
        dqa,
      } = {},
    } = action.payload;

    newState.breadcrumbs = [currentPage];
    newState.currentPage = currentPage;
    newState.pageToShow = 0;
    newState.userData = userData;
    newState.totalPages = totalPages;
    newState.projectId = projectId;
    newState.projectTitle = projectTitle;
    newState.loading = false;
    newState.isPreview = isPreview;
    newState.isTest = isTest;
    newState.dqa = dqa;

    if (campaignLanguage) {
      newState.campaignLanguage = campaignLanguage;
    }
    return newState;
  },
  [actionTypes.SURVEY_FETCH_FAILED](state) {
    return {
      ...state,
      loading: false,
    };
  },
  [actionTypes.SURVEY_PREVIOUS_PAGE]: (state) => {
    const newState = cloneDeep(state);
    newState.breadcrumbs.pop();
    newState.pageToShow = state.pageToShow - 1;
    newState.currentPage = newState.breadcrumbs[newState.breadcrumbs.length - 1];
    newState.enableNextButton = true;
    newState.isSurveyOver = false;
    return newState;
  },
  [surveyActions.SET_SURVEY_IS_OVER]: (state, action) => {
    const newState = cloneDeep(state);
    newState.isSurveyOver = action.payload;
    return newState;
  },
  [surveyActions.TOGGLE_SHOW_CONCEPT_TEST](state) {
    const { showConceptTest = false } = state;
    return { ...state, showConceptTest: !showConceptTest };
  },
  [surveyActions.TOGGLE_SHOW_HEATMAP](state) {
    const { showHeatmap } = state;
    return { ...state, showHeatmap: !showHeatmap };
  },
  [actionTypes.SURVEY_FETCH_NEXT_PAGE_REQUEST](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [actionTypes.SURVEY_FETCH_NEXT_PAGE_SUCCESS](state, action) {
    const newState = cloneDeep(state);
    const { project } = action.payload;
    const { userData, currentPage } = project;
    newState.userData = userData;
    newState.pageToShow = state.pageToShow + 1;
    newState.currentPage = currentPage;
    newState.breadcrumbs.push(currentPage);
    newState.loading = false;
    newState.enableNextButton = false;
    return newState;
  },
  [actionTypes.SURVEY_FETCH_NEXT_PAGE_FAILED](state) {
    return {
      ...state,
      loading: false,
    };
  },
  // TODO Test for this actions:
  [surveyActions.FOCUS_NEXT_QUESTION](state, action) {
    const newState = cloneDeep(state);
    const { pageToShow: currentPageNumber } = newState;
    const {
      payload: { frontendId, pages },
    } = action;
    const currentPage = pages[currentPageNumber] || {};
    const { items = [] } = currentPage;
    const currentItem = items.findIndex((item) => item === frontendId);
    newState.questionFocused = items[currentItem + 1];
    return newState;
  },
  [surveyActions.CLEAR_FOCUSED_QUESTION](state) {
    const newState = cloneDeep(state);
    newState.questionFocused = null;
    return newState;
  },
  [surveyActions.PAGE_END_REACHED](state) {
    const newState = cloneDeep(state);
    newState.enableNextButton = true;
    return newState;
  },
});
