import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEndOfSurveyPageInitialState {
  text: string;
}

const initialState: IEndOfSurveyPageInitialState = {
  text: '',
};

const endOfSurveyPageSlice = createSlice({
  name: 'endOfSurveyPage',
  initialState,
  reducers: {
    setEndOfSurveyPageText(state, action: PayloadAction<string>) {
      const text = action.payload;
      state.text = text;
    },
  },
});

export const {
  setEndOfSurveyPageText,
} = endOfSurveyPageSlice.actions;
export default endOfSurveyPageSlice.reducer;
