import { createSlice } from '@reduxjs/toolkit';

export interface IVisualizationTypesState {
  isPrintPreview: boolean;
  isQuestionPreview: boolean;
  isQuestionPreviewInsideCT: boolean;
  isViewAnswers: boolean;
}

const initialState: IVisualizationTypesState = {
  isPrintPreview: false,
  isQuestionPreview: false,
  isQuestionPreviewInsideCT: false,
  isViewAnswers: false,
};

const visualizationTypesSlice = createSlice({
  name: 'visualizationTypes',
  initialState,
  reducers: {
    setIsQuestionPreview(state) {
      state.isQuestionPreview = true;
      return state;
    },
    setIsQuestionPreviewInsideCT(state) {
      state.isQuestionPreview = true;
      state.isQuestionPreviewInsideCT = true;
      return state;
    },
    setIsViewAnswers(state) {
      state.isViewAnswers = true;
      return state;
    },
    setIsPrintPreview(state) {
      state.isPrintPreview = true;
      return state;
    },
  },
});

export const {
  setIsQuestionPreview,
  setIsQuestionPreviewInsideCT,
  setIsViewAnswers,
  setIsPrintPreview,
} = visualizationTypesSlice.actions;
export default visualizationTypesSlice.reducer;
