import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRandomizationInitialState, IRandomizationPayload } from './interfaces';

const initialState: IRandomizationInitialState = {
  options: {},
  rows: {},
};

const displayedSubItemsSlice = createSlice({
  name: 'displayedSubItems',
  initialState,
  reducers: {
    setDisplayedRows(state, action: PayloadAction<IRandomizationPayload>) {
      const { frontendId, rows = null } = action.payload;
      state.rows = {
        ...state.rows,
        [frontendId]: rows,
      };
      return state;
    },
    setDisplayedOptions(state, action: PayloadAction<IRandomizationPayload>) {
      const { frontendId, options = null } = action.payload;
      state.options = {
        ...state.options,
        [frontendId]: options,
      };
      return state;
    },
    removeDisplayedSubItemsByQuestions(state, action: PayloadAction<Array<number> | null>) {
      const itemsFrontendIds = action.payload;
      if (itemsFrontendIds) {
        itemsFrontendIds.forEach((frontendId) => {
          delete state.rows[frontendId];
          delete state.options[frontendId];
        });
      }
      return state;
    },
  },
});

export const { setDisplayedRows, setDisplayedOptions } = displayedSubItemsSlice.actions;
export default displayedSubItemsSlice.reducer;
