export const localesLangList: ILanguageCodes[] = [
  {
    key: 'amh_et',
    flag: 'et',
    alpha3code: 'eth',
    alpha2code: 'et',
    googleLanguageCode: 'am',
    languageCode: 'amh',
    value: 'amh_et',
    text: 'Amharic (ET)',
  }, // Ethiopia
  {
    key: 'ara_eg',
    flag: 'eg',
    alpha3code: 'egy',
    alpha2code: 'eg',
    googleLanguageCode: 'ar',
    languageCode: 'ara',
    value: 'ara_eg',
    text: 'Arabic (EG)',
  }, // Egypt
  {
    key: 'ara_sa',
    flag: 'sa',
    alpha3code: 'sau',
    alpha2code: 'sa',
    googleLanguageCode: 'ar',
    languageCode: 'ara',
    value: 'ara_sa',
    text: 'Arabic (SA)',
  }, // Saudi Arabia
  {
    key: 'mya_mm',
    flag: 'mm',
    alpha3code: 'mmr',
    alpha2code: 'mm',
    googleLanguageCode: 'my',
    languageCode: 'mya',
    value: 'mya_mm',
    text: 'Burmese (MM)',
  }, // Myanmar
  {
    key: 'chi_cn',
    flag: 'cn',
    alpha3code: 'chn',
    alpha2code: 'cn',
    googleLanguageCode: 'zh',
    languageCode: 'chi',
    value: 'chi_cn',
    text: 'Chinese Simplified (CN)',
  }, // china
  {
    key: 'zho_cn',
    flag: 'cn',
    alpha3code: 'chn',
    alpha2code: 'cn',
    googleLanguageCode: 'zh-TW',
    languageCode: 'zho',
    value: 'zho_cn',
    text: 'Chinese Traditional (CN)',
  }, // china
  {
    key: 'cze_cz',
    flag: 'cz',
    alpha3code: 'cze',
    alpha2code: 'cz',
    googleLanguageCode: 'cs',
    languageCode: 'cze',
    value: 'cze_cz',
    text: 'Czech (CZ)',
  },
  {
    key: 'dut_be',
    flag: 'be',
    alpha3code: 'bel',
    alpha2code: 'be',
    googleLanguageCode: 'nl',
    languageCode: 'dut',
    value: 'dut_be',
    text: 'Dutch (BE)',
  },
  {
    key: 'dut_nl',
    flag: 'nl',
    alpha3code: 'nld',
    alpha2code: 'nl',
    googleLanguageCode: 'nl',
    languageCode: 'dut',
    value: 'dut_nl',
    text: 'Dutch (NL)',
  }, // Netherlands
  {
    key: 'eng_au',
    flag: 'au',
    alpha3code: 'aus',
    alpha2code: 'au',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_au',
    text: 'English (AU)',
  }, // AUSTRALIA
  {
    key: 'eng_ca',
    flag: 'ca',
    alpha3code: 'can',
    alpha2code: 'ca',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_ca',
    text: 'English (CA)',
  }, // CANADA
  {
    key: 'eng_in',
    flag: 'in',
    alpha3code: 'ind',
    alpha2code: 'in',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_in',
    text: 'English (IN)',
  }, // INDIA
  {
    key: 'eng_ie',
    flag: 'ie',
    alpha3code: 'irl',
    alpha2code: 'ie',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_ie',
    text: 'English (IE)',
  }, // Ireland
  {
    key: 'eng_nz',
    flag: 'nz',
    alpha3code: 'nzl',
    alpha2code: 'nz',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_nz',
    text: 'English (NZ)',
  }, // New Zealand
  {
    key: 'eng_ph',
    flag: 'ph',
    alpha3code: 'phl',
    alpha2code: 'ph',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_ph',
    text: 'English (PH)',
  }, // Philippines
  {
    key: 'eng_sg',
    flag: 'sg',
    alpha3code: 'sgp',
    alpha2code: 'sg',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_sg',
    text: 'English (SG)',
  }, // Singapore
  {
    key: 'eng_za',
    flag: 'za',
    alpha3code: 'zaf',
    alpha2code: 'za',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_za',
    text: 'English (ZA)',
  }, // South Africa
  {
    key: 'eng_gb',
    flag: 'gb',
    alpha3code: 'gbr',
    alpha2code: 'gb',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_gb',
    text: 'English (GB)',
  }, // United Kingdom
  {
    key: 'eng_us',
    flag: 'us',
    alpha3code: 'usa',
    alpha2code: 'us',
    googleLanguageCode: 'en',
    languageCode: 'eng',
    value: 'eng_us',
    text: 'English (US)',
  }, // United States
  {
    key: 'fre_be',
    flag: 'be',
    alpha3code: 'bel',
    alpha2code: 'be',
    googleLanguageCode: 'fr',
    languageCode: 'fre',
    value: 'fre_be',
    text: 'French (BE)',
  }, // Belgium
  {
    key: 'fre_ca',
    flag: 'ca',
    alpha3code: 'can',
    alpha2code: 'ca',
    googleLanguageCode: 'fr',
    languageCode: 'fre',
    value: 'fre_ca',
    text: 'French (CA)',
  }, // Canada
  {
    key: 'fre_fr',
    flag: 'fr',
    alpha3code: 'fra',
    alpha2code: 'fr',
    googleLanguageCode: 'fr',
    languageCode: 'fre',
    value: 'fre_fr',
    text: 'French (FR)',
  }, // France
  {
    key: 'ger_de',
    flag: 'de',
    alpha3code: 'deu',
    alpha2code: 'de',
    googleLanguageCode: 'de',
    languageCode: 'ger',
    value: 'ger_de',
    text: 'German (DE)',
  }, // Germany
  {
    key: 'gre_gr',
    flag: 'gr',
    alpha3code: 'grc',
    alpha2code: 'gr',
    googleLanguageCode: 'el',
    languageCode: 'gre',
    value: 'gre_gr',
    text: 'Greek (GR)',
  }, // Greece
  {
    key: 'guj_in',
    flag: 'in',
    alpha3code: 'ind',
    alpha2code: 'in',
    googleLanguageCode: 'gu',
    languageCode: 'guj',
    value: 'guj_in',
    text: 'Gujarati (IN)',
  }, // India
  {
    key: 'heb_il',
    flag: 'il',
    alpha3code: 'isr',
    alpha2code: 'il',
    googleLanguageCode: 'iw',
    languageCode: 'heb',
    value: 'heb_il',
    text: 'Hebrew (IL)',
  }, // Israel
  {
    key: 'hin_in',
    flag: 'in',
    alpha3code: 'ind',
    alpha2code: 'in',
    googleLanguageCode: 'hi',
    languageCode: 'hin',
    value: 'hin_in',
    text: 'Hindi (IN)',
  }, // India
  {
    key: 'hun_hu',
    flag: 'hu',
    alpha3code: 'hun',
    alpha2code: 'hu',
    googleLanguageCode: 'hu',
    languageCode: 'hun',
    value: 'hun_hu',
    text: 'Hungarian (HU)',
  }, // hungary
  {
    key: 'ind_id',
    flag: 'id',
    alpha3code: 'ind',
    alpha2code: 'id',
    googleLanguageCode: 'id',
    languageCode: 'ind',
    value: 'ind_id',
    text: 'Indonesian (ID)',
  }, // Indonesia
  {
    key: 'ita_it',
    flag: 'it',
    alpha3code: 'ita',
    alpha2code: 'it',
    googleLanguageCode: 'it',
    languageCode: 'ita',
    value: 'ita_it',
    text: 'Italian (IT)',
  }, // Italy
  {
    key: 'jpn_jp',
    flag: 'jp',
    alpha3code: 'jpn',
    alpha2code: 'jp',
    googleLanguageCode: 'ja',
    languageCode: 'jpn',
    value: 'jpn_jp',
    text: 'Japanese (JP)',
  }, // Japan
  {
    key: 'kor_kr',
    flag: 'kr',
    alpha3code: 'kor',
    alpha2code: 'kr',
    googleLanguageCode: 'ko',
    languageCode: 'kor',
    value: 'kor_kr',
    text: 'Korean (KR)',
  }, // South Korea
  {
    key: 'may_my',
    flag: 'my',
    alpha3code: 'mys',
    alpha2code: 'my',
    googleLanguageCode: 'ms',
    languageCode: 'may',
    value: 'may_my',
    text: 'Malay (MY)',
  }, // Malaysia
  {
    key: 'pol_pl',
    flag: 'pl',
    alpha3code: 'pol',
    alpha2code: 'pl',
    googleLanguageCode: 'pl',
    languageCode: 'pol',
    value: 'pol_pl',
    text: 'Polish (PL)',
  }, // Poland
  {
    key: 'por_br',
    flag: 'br',
    alpha3code: 'bra',
    alpha2code: 'br',
    googleLanguageCode: 'pt',
    languageCode: 'por',
    value: 'por_br',
    text: 'Portuguese (BR)',
  }, // Brazil
  {
    key: 'por_pt',
    flag: 'pt',
    alpha3code: 'prt',
    alpha2code: 'pt',
    googleLanguageCode: 'pt',
    languageCode: 'por',
    value: 'por_pt',
    text: 'Portuguese (PT)',
  }, // Portugal
  {
    key: 'rum_ro',
    flag: 'ro',
    alpha3code: 'rou',
    alpha2code: 'ro',
    googleLanguageCode: 'ro',
    languageCode: 'rum',
    value: 'rum_ro',
    text: 'Romanian (RO)',
  }, // Romania
  {
    key: 'rus_ru',
    flag: 'ru',
    alpha3code: 'rus',
    alpha2code: 'ru',
    googleLanguageCode: 'ru',
    languageCode: 'rus',
    value: 'rus_ru',
    text: 'Russian (RU)',
  }, // Russia
  {
    key: 'spa_ar',
    flag: 'ar',
    alpha3code: 'arg',
    alpha2code: 'ar',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_ar',
    text: 'Spanish (AR)',
  }, // Argentina
  {
    key: 'spa_cl',
    flag: 'cl',
    alpha3code: 'chl',
    alpha2code: 'cl',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_cl',
    text: 'Spanish (CL)',
  }, // Chile
  {
    key: 'spa_co',
    flag: 'co',
    alpha3code: 'col',
    alpha2code: 'co',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_co',
    text: 'Spanish (CO)',
  }, // Colombia
  {
    key: 'spa_do',
    flag: 'do',
    alpha3code: 'dom',
    alpha2code: 'do',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_do',
    text: 'Spanish (DO)',
  }, // Dominican Republic
  {
    key: 'spa_mx',
    flag: 'mx',
    alpha3code: 'mex',
    alpha2code: 'mx',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_mx',
    text: 'Spanish (MX)',
  }, // Mexico
  {
    key: 'spa_es',
    flag: 'es',
    alpha3code: 'esp',
    alpha2code: 'es',
    googleLanguageCode: 'es',
    languageCode: 'spa',
    value: 'spa_es',
    text: 'Spanish (ES)',
  }, // Spain
  {
    key: 'swe_se',
    flag: 'se',
    alpha3code: 'swe',
    alpha2code: 'se',
    googleLanguageCode: 'sv',
    languageCode: 'swe',
    value: 'swe_se',
    text: 'Swedish (SE)',
  }, // Sweden
  {
    key: 'tgl_ph',
    flag: 'ph',
    alpha3code: 'phl',
    alpha2code: 'ph',
    googleLanguageCode: 'tl',
    languageCode: 'tgl',
    value: 'tgl_ph',
    text: 'Tagalog (PH)',
  }, // Philippines
  {
    key: 'tam_in',
    flag: 'in',
    alpha3code: 'ind',
    alpha2code: 'in',
    googleLanguageCode: 'ta',
    languageCode: 'tam',
    value: 'tam_in',
    text: 'Tamil (IN)',
  }, // India
  {
    key: 'tha_th',
    flag: 'th',
    alpha3code: 'tha',
    alpha2code: 'th',
    googleLanguageCode: 'th',
    languageCode: 'tha',
    value: 'tha_th',
    text: 'Thai (TH)',
  }, // Thailand
  {
    key: 'tur_tr',
    flag: 'tr',
    alpha3code: 'tur',
    alpha2code: 'tr',
    googleLanguageCode: 'tr',
    languageCode: 'tur',
    value: 'tur_tr',
    text: 'Turkish (TR)',
  }, // Turkey
  {
    key: 'vie_vn',
    flag: 'vn',
    alpha3code: 'vnm',
    alpha2code: 'vn',
    googleLanguageCode: 'vi',
    languageCode: 'vie',
    value: 'vie_vn',
    text: 'Vietnamese (VN)',
  }, // Vietnam
];

export interface ILanguageCodes {
  key: string;
  value: string;
  alpha2code: string;
  alpha3code: string;
  googleLanguageCode: string;
  languageCode: string;
  text: string;
  flag: string;
}

export const defaultLanguageCode: ILanguageCodes = {
  key: 'eng_us',
  flag: 'us',
  alpha3code: 'usa',
  alpha2code: 'us',
  googleLanguageCode: 'en',
  languageCode: 'eng',
  value: 'eng_us',
  text: 'English (US)',
};

export const DEFAULT_LOCALE_CODE = 'eng_us';
