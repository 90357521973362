import { createSlice } from '@reduxjs/toolkit';

interface IProjectVariableCondition {
  operator: unknown;
  questionId: unknown;
  options: unknown[];
  type: string;
}

interface IProjectVariableValue {
  operator: unknown;
  questionId: unknown;
  options: unknown[];
  type: string;
}

interface IProjectVariable {
  type: string;
  variableName: string;
  id: string;
  variableId?: string;
  condition?: IProjectVariableCondition;
  value: IProjectVariableValue;
}

export interface IProjectVariablesInitialState {
  [_id: string]: IProjectVariable[];
}

const initialState: IProjectVariablesInitialState = {};

const projectVariablesSlice = createSlice({
  name: 'projectVariables',
  initialState,
  reducers: {
    setProjectVariables(state, action) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setProjectVariables } = projectVariablesSlice.actions;
const projectVariablesReducer = projectVariablesSlice.reducer;
export default projectVariablesReducer;
