// TODO: Check unused code (authorization and such)
import * as microserviceTypes from './microservicesTypes';
import { UPDATE_TOKENS, LOG_OUT } from '../../store/actions/types/security';
import { USERS_UPDATE_REFRESH_TOKEN } from '../../store/actions/types/user';
import * as config from '../../config';
import { isStage } from '../../utils/platform';
import { reportErrorToSentry } from '../sentry';

const DOMAIN = config.API_DOMAIN;
const PROTOCOL = config.API_PROTOCOL;

export const BASE_URL = {
  [microserviceTypes.PROJECTS_CORE]: `${PROTOCOL}project${DOMAIN}/projects-api/v1`,
  [microserviceTypes.SURVEY_SERVICE]: `${PROTOCOL}survey-service${DOMAIN}/api`,
};
const addTokenIfIsAuthenticated = ({
  authenticated,
  token,
  impersonatingUserToken,
  config,
  addOriginalAuthorization,
}) => {
  if (!authenticated) {
    return config;
  }
  if (impersonatingUserToken && impersonatingUserToken !== token && addOriginalAuthorization) {
    config.headers['OriginalAuthorization'] = impersonatingUserToken;
  }
  if (token) {
    config.headers['Authorization'] = token;
    return config;
  }
  throw new Error('NO_TOKEN_SAVED');
};
const addBody = (data, configWithHeaders) => {
  if (!data) {
    return configWithHeaders;
  }
  if (configWithHeaders.headers['content-type'] === 'multipart/form-data') {
    delete configWithHeaders.headers['content-type'];
    delete configWithHeaders.headers['Accept'];
    return {
      ...configWithHeaders,
      body: data,
    };
  }
  return {
    ...configWithHeaders,
    body: JSON.stringify(data),
  };
};
const getNewRefreshToken = async (token) => {
  return fetch(`${BASE_URL[microserviceTypes.USERS]}/users/token`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken: token }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
const updateToken = async (refreshToken, refreshTokenForImpersonate, next) => {
  try {
    let response = await getNewRefreshToken(refreshToken);
    if (!response.ok) {
      next({ type: LOG_OUT });
      return;
    }
    let json = await response.json();
    next({ type: UPDATE_TOKENS, response: json });

    if (!refreshTokenForImpersonate) {
      return json;
    }
    response = await getNewRefreshToken(refreshTokenForImpersonate);
    if (!response.ok) {
      next({ type: LOG_OUT });
      return;
    }
    json = await response.json();
    next({ type: USERS_UPDATE_REFRESH_TOKEN, response: json });
    return json;
  } catch (e) {
    next({ type: LOG_OUT });
  }
};
// eslint-disable-next-line max-params
const generateMessageForSentry = (url, config, textMessage, status, data) => {
  const { method, headers } = config;
  let message = {
    title: 'API_ERROR',
    url,
    method,
    ...headers,
  };
  if (textMessage) {
    message.textMessage = textMessage;
  }
  if (status) {
    message.status = status;
  }
  if (data) {
    message.submittedData = JSON.stringify(data);
  }
  return message;
};

async function callApi({
  endpoint,
  authenticated,
  microservice,
  method,
  data,
  token,
  impersonatingUserToken,
  refreshToken,
  refreshTokenForImpersonate,
  next,
  headers = {},
  contentType = 'application/json',
  accept = 'application/json',
  addOriginalAuthorization,
}) {
  const requestHeaders = {
    'content-type': contentType,
    'Accept': accept,
    ...headers,
  };
  const configWithHeaders = addTokenIfIsAuthenticated({
    authenticated,
    token,
    impersonatingUserToken,
    config: { method, headers: requestHeaders },
    addOriginalAuthorization,
  });
  const config = addBody(data, configWithHeaders);
  let response = null;
  const url = BASE_URL[microservice] + endpoint;
  try {
    response = await fetch(url, config);
  } catch (e) {
    const messageForSentry = generateMessageForSentry(
      url,
      config,
      e.message,
      response ? response.status : 'NET_ERROR',
      data
    );
    return Promise.reject({ data: e.message, messageForSentry });
  }
  if (response.status === 401) {
    const newToken = (await updateToken(refreshToken, refreshTokenForImpersonate, next)) || {};
    if (newToken) {
      config.headers['Authorization'] = newToken.id;
      try {
        response = await fetch(BASE_URL[microservice] + endpoint, config);
      } catch (e) {
        const messageForSentry = generateMessageForSentry(url, config, e.message, response.status, data);
        return Promise.reject({
          data: e.message,
          status: response.status,
          messageForSentry,
        });
      }
    }
  }

  if (!response.ok) {
    const json = await response.json();
    const messageForSentry = generateMessageForSentry(url, config, JSON.stringify(json), response.status, data);
    return Promise.reject({
      status: response.status,
      data: json,
      messageForSentry,
    });
  }
  let json = null;
  if (response.status === 204) {
    return '';
  }
  try {
    if (accept !== 'application/json' && response.blob) {
      return await response.blob();
    }
    json = await response.json();
  } catch (e) {
    const messageForSentry = generateMessageForSentry(url, config, e.message, undefined, response);
    return Promise.reject({ data: e.message, messageForSentry });
  }
  return json;
}

const getToken = (authenticated, store) => {
  const { security } = store.getState();
  if (!authenticated || !security) {
    return null;
  }
  const { impersonate } = security;
  if (!!impersonate) {
    return impersonate.accessToken;
  }
  return security.token;
};

const getImpersonatingUserToken = (authenticated, store) => {
  const { security } = store.getState();
  if (!authenticated || !security) {
    return null;
  }
  return security.token;
};

const getRefreshTokenForImpersonate = (store) => {
  const { security } = store.getState();
  if (!security || !security.impersonate) {
    return null;
  }
  return security.impersonate.refreshToken;
};

const getRefreshToken = (store) => {
  const { security } = store.getState();
  if (!security) {
    return null;
  }
  return security.refreshToken;
};

export const CALL_API = 'CALL_API';
export const CALL_APIS = 'CALL_APIS';

const executeActionsResponse = (next, response, types) => {
  const actionTypes = [].concat(types);
  actionTypes.forEach((actionType) => {
    if (typeof actionType === 'function') {
      const { type, ...extras } = actionType(response);
      return next({ response, ...extras, type });
    }

    if (typeof actionType === 'object') {
      return next({ response, ...actionType });
    }

    return next({
      response,
      type: actionType,
    });
  });
};

// eslint-disable-next-line max-params
const executeActionsError = (next, error, types, store) => {
  if (isStage()) {
    console.log(error);
  }
  reportErrorToSentry(error, store);
  delete error.messageForSentry;

  const actionTypes = [].concat(types);

  actionTypes.forEach((actionType) => {
    if (typeof actionType === 'function') {
      const { type, ...extras } = actionType(error);
      return next({ error, ...extras, type });
    }

    if (typeof actionType === 'object') {
      return next({ error, ...actionType });
    }

    return next({
      error,
      type: actionType,
    });
  });
};

const middleware = (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }
  const {
    endpoint,
    types,
    authenticated,
    method,
    data,
    microservice,
    contentType,
    accept,
    headers,
    addOriginalAuthorization,
  } = callAPI;

  const [requestType, successTypes, errorTypes] = types;

  if (typeof requestType === 'object') {
    next(requestType);
  } else {
    next({
      type: requestType,
    });
  }

  const token = getToken(authenticated, store);
  const impersonatingUserToken = getImpersonatingUserToken(authenticated, store);
  const refreshToken = getRefreshToken(store);
  const refreshTokenForImpersonate = getRefreshTokenForImpersonate(store);

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi({
    endpoint,
    authenticated,
    microservice,
    method,
    data,
    token,
    impersonatingUserToken,
    refreshToken,
    refreshTokenForImpersonate,
    next,
    contentType,
    accept,
    headers,
    addOriginalAuthorization,
  })
    .then((response) => executeActionsResponse(next, response, successTypes))
    .catch((error) => executeActionsError(next, error, errorTypes, store));
};

const performRequest = ({ call, next, store, requestType }) => {
  const {
    endpoint,
    authenticated,
    method,
    data,
    microservice,
    contentType,
    accept,
    headers,
    addOriginalAuthorization,
  } = call;

  if (typeof requestType === 'object') {
    next(requestType);
  } else {
    next({ type: requestType });
  }

  const token = getToken(authenticated, store);
  const impersonatingUserToken = getImpersonatingUserToken(authenticated, store);
  const refreshToken = getRefreshToken(store);
  const refreshTokenForImpersonate = getRefreshTokenForImpersonate(store);

  return callApi({
    endpoint,
    authenticated,
    microservice,
    method,
    data,
    token,
    impersonatingUserToken,
    refreshToken,
    refreshTokenForImpersonate,
    next,
    contentType,
    accept,
    headers,
    addOriginalAuthorization,
  });
};

export const middlewareConcurrentRequests = (store) => (next) => (action) => {
  const callAPIS = action[CALL_APIS];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPIS === 'undefined') {
    return next(action);
  }
  const [requestType, successTypes, errorTypes] = callAPIS.types;
  const calls = [];
  for (const call of callAPIS.calls) {
    calls.push(performRequest({ call, next, store, requestType }));
  }
  Promise.all(calls)
    .then((responses) => executeActionsResponse(next, responses, successTypes))
    .catch((error) => executeActionsError(next, error, errorTypes, store));
};

export default middleware;
