import * as ActionTypes from '../../actions/types/activeProject';
import createReducer from '../createReducer';

const initialState = {};

export default createReducer(initialState, {
  [ActionTypes.SURVEY_FETCH_REQUEST]() {
    return {};
  },
  [ActionTypes.SHOW_SECTION](state, action) {
    const { page } = action.payload;
    if (page === undefined) {
      return { ...state };
    }
    return { ...state, [page]: true };
  },
  [ActionTypes.HIDE_SECTION](state, action) {
    const { page } = action.payload;
    if (page === undefined) {
      return { ...state };
    }
    return { ...state, [page]: false };
  },
  [ActionTypes.CLEAR_QUESTION_VISIBLE]() {
    return {};
  },
});
