import React from 'react';

import Loader from '../common/loader';
import RouteErrorBoundary from '../common/routeErrorBoundary';

const TIMEOUT = 1000; //15000;
const DELAY = 200;
const RETRIES = 10;

function retry(fn, retriesLeft = RETRIES, interval = TIMEOUT) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            error.networkFailed = true;
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          console.log('retry -> retriesLeft', retriesLeft);
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const delay = (callback) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(callback());
    }, DELAY);
  });
};

export const createLoadable = (loader) => {
  return React.lazy(() => delay(() => retry(async () => loader())));
};

export const Suspense = (props) => {
  return (
    <RouteErrorBoundary>
      <React.Suspense fallback={<Loader />}>{props.children ? props.children : null}</React.Suspense>
    </RouteErrorBoundary>
  );
};
