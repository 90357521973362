import {
  SURVEY_FETCH_SUCCESS,
  SURVEY_FETCH_REQUEST,
  SURVEY_FETCH_NEXT_PAGE_SUCCESS,
  SURVEY_PREVIOUS_PAGE,
} from '../../../actions/types/activeProject';
import { SET_EXPERIMENTS_CONFIG } from '../../../actions/types/responses';
import { hasQuestionPerRow, isConjointExperiment, isMaxDiffExperiment } from '../../../../components/utils/helpers';
import createReducer from '../../createReducer';

import rfdc from 'rfdc';
const cloneDeep = rfdc();

const initialState = {};

const addExtraData = ({ items, pageFrontendId, rows }) => {
  for (const item of Object.values(items)) {
    item.pageFrontendId = pageFrontendId;

    if (hasQuestionPerRow(item)) {
      for (const rowFrontendId of item.rows) {
        const row = rows[rowFrontendId];
        items[row.questionFrontendId] = {
          ...item,
          frontendId: row.questionFrontendId,
          _id: row.questionId,
        };
      }
    }

    if (item.config && item.config.rowPerPage) {
      const row = rows[item.rows[0]];
      item.showOnlyRow = row.position;
    }
  }
};

export default createReducer(initialState, {
  [SURVEY_FETCH_REQUEST]() {
    return {};
  },
  [SURVEY_FETCH_SUCCESS](state, action) {
    const { items = {}, pageFrontendId, rows = {} } = action.payload;
    addExtraData({ items, pageFrontendId, rows });
    return { ...items };
  },
  [SURVEY_FETCH_NEXT_PAGE_SUCCESS](state, action) {
    const { items = {}, rows = {}, pageFrontendId } = action.payload;
    addExtraData({ items, pageFrontendId, rows });
    return { ...state, ...items };
  },
  [SURVEY_PREVIOUS_PAGE](state, action) {
    const { items = {}, rows = {}, pageFrontendId } = action.payload;
    addExtraData({ items, pageFrontendId, rows });
    return { ...state, ...items };
  },
  [SET_EXPERIMENTS_CONFIG](state, action) {
    const { experiments: experimentsFrontendId, responses } = action.payload;
    const items = {};

    for (const itemFrontendId of experimentsFrontendId) {
      const item = cloneDeep(state[itemFrontendId]) || {};
      const { experiment = {} } = item;

      const setValues = responses[item.frontendId] ? responses[item.frontendId].setValues : [];
      if (isMaxDiffExperiment(item) && setValues && setValues.length) {
        const newExperiment = { ...experiment };
        const { index } = newExperiment;
        const { definition } = setValues[index];
        newExperiment.solution = Object.values(definition).map((row) => ({
          itemId: row.rowPositionFromItem - 1,
          position: row.positionInSet,
        }));

        item.experiment = newExperiment;
      }

      if (isConjointExperiment(item)) {
        const newExperiment = { ...experiment };
        const { index } = newExperiment;
        const { cards } = responses[item.frontendId].values[index];
        experiment.sets = cards.map((card) => {
          const { group, attributes } = card;
          return {
            attributes: attributes.reduce((acc, itemId, index) => {
              return {
                ...acc,
                [index]: itemId,
              };
            }, {}),
            group,
          };
        });
        item.experiment = experiment;
      }

      items[item.frontendId] = item;
    }

    return {
      ...state,
      ...items,
    };
  },
});
