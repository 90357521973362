import React, { Component, ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from './common/loader';
// TODO: add these components
//import FrontendChangeDetector from './common/frontendChangeDetector';
//import BrowserVersionCheck from './common/browserVersionCheck';
import { Capture, CaptureSubmitted, NotAvailable, NotFound, OverQuota, PrintPreview, ViewAnswers } from './routes';
import { Features, isFeatureEnabled } from '../featureToggles/features';

class RootRouter extends Component {
  render(): ReactNode {
    return (
      <React.Suspense fallback={Loader}>
        <Switch>
          <Route exact path="/printPreview/:projectId">
            <PrintPreview />
          </Route>
          <Route
            exact
            path={isFeatureEnabled(Features.conjoint) ? '/viewAnswer/:projectId/:responseId' : '/viewAnswer/:projectId'}
          >
            <ViewAnswers />
          </Route>
          <Route exact path="/preview/:projectId/question/:questionFrontendId">
            <Capture isPreview />
          </Route>
          <Route exact path="/preview/:projectId">
            <Capture isPreview />
          </Route>
          <Route exact path="/not-available">
            <NotAvailable />
          </Route>
          <Route exact path="/disqualified">
            <NotAvailable />
          </Route>
          <Route exact path="/over-quota">
            <OverQuota />
          </Route>
          <Route exact path="/closed">
            <NotAvailable
              message={
                <>
                  Thank you for your interest in taking part in our survey,
                  <br />
                  however this survey it's no longer available
                </>
              }
            />
          </Route>
          <Route exact path="/only-mobile">
            <NotAvailable message={<>This survey is only available for mobile</>} />
          </Route>
          <Route exact path="/only-desktop">
            <NotAvailable message={<>This survey is only available for desktop</>} />
          </Route>
          <Route exact path="/complete">
            <CaptureSubmitted />
          </Route>
          <Route exact path="/:hash">
            <Capture />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </React.Suspense>
    );
  }
}

export default RootRouter;
