import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConceptTestInitialState } from './interfaces';

const initialState: IConceptTestInitialState = {
  showedConceptTestTitle: {},
  showedConceptTestPreview: {},
};

const conceptTestSlice = createSlice({
  name: 'conceptTest',
  initialState,
  reducers: {
    setShowedConceptTestTitle(state, action: PayloadAction<string>) {
      const { payload: conceptTestId } = action;
      state.showedConceptTestTitle[conceptTestId] = true;
      return state;
    },
    setShowedConceptPreview(state, action: PayloadAction<string>) {
      const { payload: conceptId } = action;
      state.showedConceptTestPreview[conceptId] = true;
      return state;
    },
  },
});

export const { setShowedConceptTestTitle, setShowedConceptPreview } = conceptTestSlice.actions;
const conceptTestReducer = conceptTestSlice.reducer;
export default conceptTestReducer;
