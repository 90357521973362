import { DISPOSE_NOTIFICATION, SHOW_NOTIFICATION } from './types/notifications';

export const showNotification = ({ message, level }) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: { message, level },
  };
};

export const disposeNotification = () => {
  return { type: DISPOSE_NOTIFICATION };
};
