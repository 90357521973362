import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { overwriteConsoleLog } from './services/consoleLogOverwrite';
import { loadSentry } from './services/sentry';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/antd.less';
import './index.scss';
import './assets/sx-icons/style.css';
import './antOverrides.scss';
import './styles/quill.scss';

overwriteConsoleLog();
loadSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
