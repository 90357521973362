const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+=()]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
); // fragment locator

export const isValidUrl = (url) => urlRegex.test(url);

export const addUrlParams = (url, params = {}) => {
  let result = url;
  for (const key of Object.keys(params)) {
    const newParam = `${key}=${params[key]}`;
    if (result.indexOf(`${key}=`) !== -1) {
      result = result.replace(new RegExp(`(&|\\?)${key}=[^&|#]*`), `$1${newParam}`);
      continue;
    }
    if (result.indexOf('?') !== -1) {
      result = `${result.split('?')[0]}?${newParam}&${result.split('?')[1]}`;
      continue;
    }
    if (result.indexOf('#') !== -1) {
      result = `${result.split('#')[0]}?${newParam}#${result.split('#')[1]}`;
      continue;
    }
    result = `${result}?${newParam}`;
  }
  return result;
};

export function getVariablePairs() {
  const query = window.location.search.substring(1);
  return query.split('&');
}

export const getQueryVariables = (variables = []) => {
  const vars = getVariablePairs();
  const variablesMap = {};
  for (const variable of vars) {
    const pair = variable.split('=');
    if (variables.includes(decodeURIComponent(pair[0]))) {
      variablesMap[pair[0]] = pair[1];
    }
  }
  return variablesMap;
};

export const getAllQueryVariables = () => {
  const vars = new URLSearchParams(window.location.search);
  const variablesMap = {};
  for (const [key, value] of vars) {
    if (key === 'test' && value === '') {
      variablesMap[key] = 1;
      continue;
    }
    variablesMap[key] = value;
  }
  return variablesMap;
};

export const cleanQueryPanelVariables = (urlVariables) => {
  const variablesToClean = ['isPreview', 'isTest', 'test', 'forceMobile', 'isAdmin', 'admin'];
  for (let key of variablesToClean) {
    delete urlVariables[key];
  }
};
