import responsesReducer from './responses';
import responsesErrorsReducer from './responsesErrors';
import activeProjectReducer from './activeProject';
import captureReducer from './capture';
import notificationReducer from './notification';

export const reducers = {
  activeProject: activeProjectReducer,
  capture: captureReducer,
  notification: notificationReducer,
  responsesErrors: responsesErrorsReducer,
};

export default reducers;
