export const defaultColors = {
  success: {
    rgb: '94, 164, 0',
    hex: '#34c759',
    iconShadow: 'rgba(52, 199, 89, 0.1)',
  },
  error: {
    rgb: '236, 61, 61',
    hex: 'rgb(204, 0, 0)',
    shadow: 'rgba(146, 21, 21, 0.9) 0px 0px 1px',
    icon: 'rgb(197, 91, 85)',
    iconShadow: 'rgba(215, 51, 50, 0.1)',
  },
  warning: {
    rgb: '235, 173, 23',
    hex: '#ff9500',
    iconShadow: 'rgba(255, 149, 0, 0.1)',
  },
  info: {
    rgb: '54, 156, 199',
    hex: '#007aff',
    iconShadow: 'rgba(0, 123, 255, 0.1)',
  },
};

const styles = {
  Containers: {
    DefaultStyle: {
      width: 'auto',
      minWidth: '500px',
    },
    tc: {
      top: '0',
      bottom: 'auto',
      margin: '0 auto',
      left: 'none',
      marginLeft: '15px',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: '#fff',
      margin: '3px 0px 0px',
      color: '#181913',
      borderTopWidth: 0,
      padding: '15px',
      paddingRight: '44px',
      fontSize: '14px',
      top: '19px',
    },
    success: {
      borderLeft: '7px solid ' + defaultColors.success.hex,
    },
    error: {
      borderLeft: '7px solid ' + defaultColors.error.hex,
    },

    warning: {
      borderLeft: '7px solid ' + defaultColors.warning.hex,
    },

    info: {
      borderLeft: '7px solid ' + defaultColors.info.hex,
    },
  },
  Dismiss: {
    DefaultStyle: {
      display: 'none',
    },
    success: {
      color: '#929292',
      backgroundColor: '#f9f9f9',
    },
    error: {
      color: '#929292',
      backgroundColor: '#f9f9f9',
    },
    warning: {
      color: '#929292',
      backgroundColor: '#f9f9f9',
    },
    info: {
      color: '#929292',
      backgroundColor: '#f9f9f9',
    },
  },
};

export default styles;
