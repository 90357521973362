import rfdc from 'rfdc';

import createReducer from '../createReducer';
import {
  ON_CHANGE_ITEM,
  CLEAR_QUESTION_RESPONSES,
  SUBMIT_RESPONSES_SUCCESS,
  CLEAR_RESPONSES,
  CLEAR_RESPONSE,
  CLEAR_DEPENDENT_QUESTIONS,
  LOAD_RESPONSES,
  SET_EMBEDDED_VARIABLES,
  SET_URL_VARIABLES_RESPONSES,
  SET_SCREENERS_RESPONSES,
} from '../../actions/types/responses';

import changeItemReducer from './changeItem';

const cloneDeep = rfdc();

const clearDependentQuestions = (answers, parentFrontendId, items) => {
  const item = items[parentFrontendId];
  const { dependentQuestions = [] } = item || {};

  if (!dependentQuestions || dependentQuestions.length === 0) {
    return;
  }

  for (const frontendId of dependentQuestions) {
    delete answers[frontendId];
    clearDependentQuestions(answers, frontendId, items);
  }
};

/** @deprecated */
export default createReducer(
  {},
  {
    [SET_SCREENERS_RESPONSES](state, action) {
      const { responses = {} } = action.payload;
      return { ...state, ...responses };
    },
    [SET_URL_VARIABLES_RESPONSES](state, action) {
      const { responses = {} } = action.payload;
      return { ...state, ...responses };
    },
    [SET_EMBEDDED_VARIABLES](state, action) {
      const { embeddedVariables = {} } = action.payload;
      return { ...state, ...embeddedVariables };
    },
    [CLEAR_RESPONSES]() {
      return {};
    },
    [CLEAR_RESPONSE](state, action) {
      const newState = { ...state };
      const { frontendId } = action.payload;
      delete newState[frontendId];
      return newState;
    },
    [ON_CHANGE_ITEM](state, action) {
      const newState = { ...state };
      newState[action.payload.frontendId] = changeItemReducer(newState[action.payload.frontendId], action);
      return newState;
    },
    [CLEAR_QUESTION_RESPONSES](state, action) {
      const { questionsFrontendId = [] } = action.payload;
      const newState = { ...state };
      questionsFrontendId.forEach((frontendId) => {
        delete newState[frontendId.toString()];
      });
      return newState;
    },
    [SUBMIT_RESPONSES_SUCCESS]() {
      return {};
    },
    [CLEAR_DEPENDENT_QUESTIONS](state, action) {
      const newState = cloneDeep(state);
      const { changedFrontendId, items } = action.payload;

      clearDependentQuestions(newState, changedFrontendId, items);
      return newState;
    },
    [LOAD_RESPONSES](_, action) {
      return action.payload;
    },
  }
);
