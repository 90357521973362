import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import errorOwl from '../../assets/owl/internet-fail-owl.svg';
import sightXLogo from '../../assets/sightXLogo.svg';

import styles from './routeErrorBoundary.module.scss';

class RouteErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
    const imgOwl = new Image();
    imgOwl.src = errorOwl;
    const logoOwl = new Image();
    logoOwl.src = sightXLogo;
  }

  static getDerivedStateFromError(error) {
    return { hasError: !!error.networkFailed };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div className={styles.errorScreen}>
          <div>
            <img className={styles.sightxLogo} alt="SightX logo" src={sightXLogo} />
          </div>
          <div className={styles.centerMessage}>
            <img className={styles.errorScreenImage} alt="Error owl" src={errorOwl} />
            <p className={styles.errorMessage}>Your connection seems to be unstable</p>
            <p className={styles.infoText}>If you keep getting this message please contact your Internet provider.</p>
            <p className={styles.reloadText}>
              <span
                onClick={() => {
                  window.location.reload(true);
                }}
                className={styles.reloadButton}
              >
                Reload
              </span>{' '}
              and try again
            </p>
          </div>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default RouteErrorBoundary;
