import rfdc from 'rfdc';

import createReducer from '../createReducer';
import {
  SUBMIT_RESPONSES_FAILED,
  SUBMIT_RESPONSES_SUCCESS,
  SUBMIT_RESPONSES_REQUEST,
  CAPTURE_CHANGE_LANGUAGE,
} from '../../actions/types/responses';

import {
  SURVEY_FETCH_REQUEST,
  SURVEY_FETCH_SUCCESS,
  SURVEY_FETCH_FAILED,
  SET_CAMPAIGN_HAS_ERRORS,
} from '../../actions/types/activeProject';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { DEFAULT_LOCALE_CODE, defaultLanguageCode, localesLangList } from '../../../data/locales';

const cloneDeep = rfdc();

const INITIAL_STATE = {
  availableLanguages: [], // should be renamed to availableLocales
  error: undefined,
  selectedLanguage: undefined, // should be renamed to selectedLocale
  submitting: false,
  submitted: false,
};

export default createReducer(INITIAL_STATE, {
  [SET_CAMPAIGN_HAS_ERRORS](state) {
    return { ...state, hasError: true };
  },
  [SURVEY_FETCH_REQUEST](state) {
    return { ...state, selectedLanguage: undefined };
  },
  [SURVEY_FETCH_SUCCESS](state, action) {
    if (!action.setDefaultLanguage) {
      return { ...state };
    }
    const { project, language } = action.payload;
    const {
      availableLanguages: projectAvailableLanguages = [],
      campaignLanguage,
      customization: { defaultLanguage = 'en', defaultLocale = DEFAULT_LOCALE_CODE } = {},
      availableLocales: projectAvailableLocales = [],
      campaignLocale,
    } = project;
    const availableLanguages = isFeatureEnabled(Features.panel) ? projectAvailableLocales : projectAvailableLanguages;
    let selectedLanguage = availableLanguages.includes(language)
      ? language
      : availableLanguages.includes(campaignLanguage)
      ? campaignLanguage
      : defaultLanguage;

    if (isFeatureEnabled(Features.panel)) {
      selectedLanguage = projectAvailableLocales.includes(campaignLocale) ? campaignLocale : defaultLocale;
    }
    return { ...state, selectedLanguage, availableLanguages };
  },
  [SURVEY_FETCH_FAILED](state) {
    return {
      ...state,
      hasError: true,
    };
  },
  [CAPTURE_CHANGE_LANGUAGE](state, action) {
    return { ...state, selectedLanguage: action.payload.selectedLanguage };
  },
  [SUBMIT_RESPONSES_REQUEST](state) {
    const newState = cloneDeep(state);
    return {
      ...newState,
      submitted: false,
      submitting: true,
    };
  },
  [SUBMIT_RESPONSES_SUCCESS](state) {
    const newState = cloneDeep(state);
    return {
      ...newState,
      submitted: true,
      error: undefined,
      submitting: false,
    };
  },
  [SUBMIT_RESPONSES_FAILED](state) {
    const newState = cloneDeep(state);
    return {
      ...newState,
      submitted: true,
      submitting: false,
      error: 'An error occurred, please try submitting your responses again',
    };
  },
});
