// TODO: Check unused code
import * as activeProjectActions from '../../../actions/types/activeProject';
import createReducer from '../../createReducer';

const initialState = {};

export default createReducer(initialState, {
  [activeProjectActions.SURVEY_FETCH_REQUEST]() {
    return {};
  },
  [activeProjectActions.SURVEY_FETCH_SUCCESS](state, action) {
    const { options } = action.payload;
    return { ...options };
  },
  [activeProjectActions.SURVEY_FETCH_NEXT_PAGE_SUCCESS](state, action) {
    const { options } = action.payload;
    return { ...state, ...options };
  },
});
