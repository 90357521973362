import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const pageShownCountersSlice = createSlice({
  name: 'pageShownCounters',
  initialState,
  reducers: {
    setPageShownCounters(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const { setPageShownCounters } = pageShownCountersSlice.actions;
export default pageShownCountersSlice.reducer;
