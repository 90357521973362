import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import apiMiddleware, { middlewareConcurrentRequests } from '../services/middleware';
import { rootReducer, routerHistory } from './rootReducer';
import { isDebugModeEnabled } from '../services/queryParameters';

const middleware = [
  routerMiddleware(routerHistory),
  apiMiddleware,
  middlewareConcurrentRequests,
  ...getDefaultMiddleware(),
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: isDebugModeEnabled,
});

export const rootState = store.getState();

export default store;
