// TODO: Check unused actions
export const CRITERIA_ADD_VARIABLE = 'CRITERIA_ADD_VARIABLE';
export const CRITERIA_REPLACE_VARIABLE = 'CRITERIA_REPLACE_VARIABLE';
export const CRITERIA_DELETE_VARIABLE = 'CRITERIA_DELETE_VARIABLE';

export const CRITERIA_ADD_CRITERION = 'CRITERIA_ADD_CRITERION';
export const CRITERIA_UPDATE_CRITERION = 'CRITERIA_UPDATE_CRITERION';
export const CRITERIA_DELETE_CRITERION = 'CRITERIA_DELETE_CRITERION';

export const CRITERIA_CLEAR_VARIABLE_MATCHES = 'CRITERIA_CLEAR_VARIABLE_MATCHES';
export const CRITERIA_CLEAR_CRITERION_ITEMS = 'CRITERIA_CLEAR_CRITERION_ITEMS';
export const CRITERIA_DELETE_CRITERIA = 'CRITERIA_DELETE_CRITERIA';
