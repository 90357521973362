export const TRANSFORM_PROJECT_FOR_SURVEY = 'TRANSFORM_PROJECT_FOR_SURVEY';
export const SURVEY_ABORTED = 'SURVEY_ABORTED';
export const MAXDIFF_EXPERIMENT_SURVEY_FETCHING = 'MAXDIFF_EXPERIMENT_SURVEY_FETCHING';
export const MAXDIFF_EXPERIMENT_SURVEY_FETCH_SUCCESS = 'MAXDIFF_EXPERIMENT_SURVEY_FETCH_SUCCESS';
export const MAXDIFF_EXPERIMENT_SURVEY_FETCH_FAIL = 'MAXDIFF_EXPERIMENT_SURVEY_FETCH_FAIL';
export const CONJOINT_EXPERIMENT_SURVEY_FETCHING = 'CONJOINT_EXPERIMENT_SURVEY_FETCHING';
export const CONJOINT_EXPERIMENT_SURVEY_FETCH_SUCCESS = 'CONJOINT_EXPERIMENT_SURVEY_FETCH_SUCCESS';
export const CONJOINT_EXPERIMENT_SURVEY_FETCH_FAIL = 'CONJOINT_EXPERIMENT_SURVEY_FETCH_FAIL';
export const COLLAPSE_EXPERIMENT_PAGES = 'COLLAPSE_EXPERIMENT_PAGES';
export const PAGE_END_REACHED = 'PAGE_END_REACHED';
export const FOCUS_NEXT_QUESTION = 'FOCUS_NEXT_QUESTION';
export const CLEAR_FOCUSED_QUESTION = 'CLEAR_FOCUSED_QUESTION';
export const TOGGLE_SHOW_CONCEPT_TEST = 'TOGGLE_SHOW_CONCEPT_TEST';
export const ADD_SKIPPED_PAGE = 'ADD_SKIPPED_PAGE';
export const SET_RECIPIENT_VARIABLES_FOR_PREVIEW = 'SET_RECIPIENT_VARIABLES_FOR_PREVIEW';
export const TOGGLE_SHOW_HEATMAP = 'TOGGLE_SHOW_HEATMAP';
export const SET_SURVEY_IS_OVER = 'SET_SURVEY_IS_OVER';
