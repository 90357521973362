// TODO: Check unused code

import rfdc from 'rfdc';
import createReducer from '../createReducer';
import {
  ON_CHANGE_ITEM,
  CLEAR_QUESTION_RESPONSES,
  ADD_ERROR,
  REMOVE_ERRORS,
  SUBMIT_RESPONSES_SUCCESS,
  CLEAR_RESPONSES,
} from '../../actions/types/responses';
import { FOCUS_NEXT_QUESTION } from '../../actions/types/survey';

const cloneDeep = rfdc();
const initialState = {};

export default createReducer(initialState, {
  [CLEAR_RESPONSES]() {
    return {};
  },
  [ON_CHANGE_ITEM](state, action) {
    const newState = { ...state };
    const itemsToDelete = Object.keys(newState).filter((key) =>
      key.toString().includes(action.payload.frontendId.toString())
    );
    itemsToDelete.forEach((item) => delete newState[item]);
    return newState;
  },
  [CLEAR_QUESTION_RESPONSES](state, action) {
    const { questionsFrontendId = [] } = action.payload;
    const newState = { ...state };
    questionsFrontendId.forEach((frontendId) => {
      delete newState[frontendId.toString()];
    });
    return newState;
  },
  [REMOVE_ERRORS]() {
    return {};
  },
  [ADD_ERROR](state, action) {
    const newState = cloneDeep(state);
    const isFirstError = Object.keys(newState).length === 0;
    return {
      ...newState,
      [action.payload.frontendId]: { error: action.payload.error, isFirstError },
    };
  },
  [SUBMIT_RESPONSES_SUCCESS]() {
    return {};
  },
  [FOCUS_NEXT_QUESTION](state, action) {
    const { frontendId } = action.payload;
    const newState = { ...state };
    delete newState[frontendId];
    return newState;
  },
});
