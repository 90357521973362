import * as ActionTypes from '../../actions/types/activeProject';
import createReducer from '../createReducer';

const initialState = true;

export default createReducer(initialState, {
  [ActionTypes.SHOW_SECTION]() {
    return true;
  },
  [ActionTypes.HIDE_SECTION]() {
    return false;
  },
});
