import rfdc from 'rfdc';

import createReducer from '../createReducer';
import { ON_CHANGE_ITEM } from '../../actions/types/responses';
import { ITEM_SUBTYPES } from '../../../components/types/objectTypes';

const cloneDeep = rfdc();

const isValuesEmpty = (values) => {
  return (
    !!values &&
    ((Array.isArray(values) && values.length === 0) || (typeof values === 'object' && Object.keys(values).length === 0))
  );
};

const isValueEmpty = (payloadValue) => {
  return !payloadValue.na && !payloadValue.other && (payloadValue.value === undefined || payloadValue.value === null);
};

export default createReducer(
  {},
  {
    [ON_CHANGE_ITEM](state, { payload }) {
      const currentAnswer = cloneDeep(state);
      delete currentAnswer.error;
      const response = { na: false, ...(Array.isArray(currentAnswer) ? {} : currentAnswer) };
      if ((isValueEmpty(payload.value) && !payload.value.values) || isValuesEmpty(payload.value.values)) {
        const { loopingPageIndex } = getValue(payload);
        if (loopingPageIndex === undefined || state.length < 2) {
          return undefined;
        }
        const newState = Array.isArray(currentAnswer) ? [...currentAnswer] : [];
        newState.splice(loopingPageIndex, 1);
        return newState;
      }
      if (currentAnswer && currentAnswer.na && payload.value.value === 0) {
        return state;
      }
      if (payload.subtype) {
        if ([ITEM_SUBTYPES.vanWestendorp, ITEM_SUBTYPES.keyDriver].includes(payload.subtype)) {
          currentAnswer.values = {
            ...payload.value.values,
          };
          return currentAnswer;
        }
        if (payload.subtype === ITEM_SUBTYPES.comments) {
          return {
            ...response,
            comments: payload.value.value,
          };
        }
        if (payload.subtype === ITEM_SUBTYPES.pastedText) {
          return {
            ...response,
            pastedText: payload.value.value,
          };
        }
        if (payload.subtype === ITEM_SUBTYPES.maxDiffExperiment && payload.value.definition) {
          return {
            ...response,
            values: payload.value.values,
            definition: payload.value.definition,
          };
        }
      }
      const { loopingPageIndex, ...rest } = getValue(payload);
      if (loopingPageIndex === undefined) {
        return { ...response, ...rest };
      }
      const newState = Array.isArray(currentAnswer) ? [...currentAnswer] : [];
      newState[loopingPageIndex] = { ...response, ...rest };
      return newState;
    },
  }
);

const getValue = (payload) => {
  if (payload.subtype && payload.subtype === ITEM_SUBTYPES.number) {
    if (typeof payload.value.value === 'string') {
      let value = payload.value.value;
      if (payload.value.value.slice(-1) !== '.') {
        value = parseFloat(payload.value.value);
      }
      return { value };
    }
  }
  return payload.value;
};
