// TODO: Check unused code
import forEach from 'lodash/forEach';
import {
  SURVEY_FETCH_SUCCESS,
  SURVEY_FETCH_REQUEST,
  SURVEY_FETCH_NEXT_PAGE_SUCCESS,
} from '../../../actions/types/activeProject';
import createReducer from '../../createReducer';

const initialState = {};

export default createReducer(initialState, {
  [SURVEY_FETCH_REQUEST]() {
    return {};
  },
  [SURVEY_FETCH_SUCCESS](state, action) {
    let rows = action.payload.rows;
    // setup itemId
    forEach(action.payload.items, (item, key) => {
      forEach(item.rows, (rowId) => {
        let copyRow = rows[rowId];
        // Set the itemId if it does not exists
        if (copyRow && !copyRow.itemId) {
          copyRow.itemId = key * 1;
        }
      });
    });
    return { ...rows };
  },
  [SURVEY_FETCH_NEXT_PAGE_SUCCESS](state, action) {
    const rows = action.payload.rows;
    return { ...state, ...rows };
  },
});
