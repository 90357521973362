// TODO: Remove file
export const LOGGING_IN = 'LOGGING_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';

export const LOG_OUT = 'LOG_OUT';

export const UPDATE_TOKENS = 'UPDATE_TOKENS';

export const SET_CURRENT_USER_AVATAR = 'SET_CURRENT_USER_AVATAR';

export const SET_IMPERSONATED_USER_AVATAR = 'SET_IMPERSONATED_USER_AVATAR';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILED = 'UPDATE_USER_PASSWORD_FAILED';

export const FETCHING_RECREATION_DATA = 'FETCHING_RECREATION_DATA';
export const FETCHING_RECREATION_DATA_FAILED = 'FETCHING_RECREATION_DATA_FAILED';
export const FETCHING_RECREATION_DATA_SUCCEEDED = 'FETCHING_RECREATION_DATA_SUCCEEDED';

export const DELETING_PROJECTS_DATA = 'DELETING_PROJECTS_DATA';
export const DELETING_PROJECTS_DATA_FAILED = 'DELETING_PROJECTS_DATA_FAILED';
export const DELETING_PROJECTS_DATA_SUCCEEDED = 'DELETING_PROJECTS_DATA_SUCCEEDED';

export const RECREATE_PROJECTS_DATA = 'RECREATE_PROJECTS_DATA';
export const RECREATE_PROJECTS_DATA_FAILED = 'RECREATE_PROJECTS_DATA_FAILED';
export const RECREATE_PROJECTS_DATA_SUCCEEDED = 'RECREATE_PROJECTS_DATA_SUCCEEDED';

export const SECURITY_CLEAR_PROJECT_ACCESS = 'SECURITY_CLEAR_PROJECT_ACCESS';
