import {
  SURVEY_FETCH_SUCCESS,
  SURVEY_FETCH_REQUEST,
  SURVEY_FETCH_NEXT_PAGE_SUCCESS,
  SURVEY_PREVIOUS_PAGE,
} from '../../actions/types/activeProject';
import createReducer from '../createReducer';

const initialState = [];

const getLastQuestionNumber = (page, items, currentLastQuestionNumber) => {
  let lastQuestionNumber = currentLastQuestionNumber;
  let i = -1;
  if (page.items) {
    for (let i = page.items.length - 1; i >= 0; i--) {
      const item = items[page.items[i]] || {};
      if (item.questionNumberForSurvey !== undefined) {
        lastQuestionNumber = item.questionNumberForSurvey;
        break;
      }
    }
    return lastQuestionNumber;
  }
};

export default createReducer(initialState, {
  [SURVEY_FETCH_REQUEST]() {
    return initialState;
  },
  [SURVEY_FETCH_SUCCESS](state, action) {
    const { items, pages, pageFrontendId } = action.payload;
    const page = { ...pages[pageFrontendId] };
    page.lastQuestionNumber = getLastQuestionNumber(page, items);
    return [page];
  },
  [SURVEY_FETCH_NEXT_PAGE_SUCCESS](state, action) {
    const { items, pages, pageFrontendId } = action.payload;
    const page = { ...pages[pageFrontendId] };
    page.lastQuestionNumber = getLastQuestionNumber(page, items, state[state.length - 1].lastQuestionNumber);
    return [...state, page];
  },
  [SURVEY_PREVIOUS_PAGE](state) {
    return [...state.slice(0, state.length - 1)];
  },
});
