import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMethodologyState } from './interfaces';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';

const initialState: IMethodologyState = {
  showedMethodologyCovers: {},
};

const methodologySlice = createSlice({
  name: 'methodology',
  initialState,
  reducers: {
    setShowedCover(state, action: PayloadAction<string>) {
      if (!isFeatureEnabled(Features.conjoint)) {
        return state;
      }
      const { payload: coverId } = action;
      state.showedMethodologyCovers[coverId] = true;
    },
  },
});

export const { setShowedCover } = methodologySlice.actions;
export const methodologyReducer = methodologySlice.reducer;
