import { isOnlyProduction } from '../utils/platform';

//Query parameters
//in case older browsers like ie
const urlSearchParams = URLSearchParams
  ? new URLSearchParams(window.location.search)
  : { get: () => null, keys: () => [] };
const params = new Set(urlSearchParams.keys());

export const isDebugModeEnabled = !isOnlyProduction() || params.has('debug');
export const getQueryParam = urlSearchParams.get.bind(urlSearchParams);
