import rfdc from 'rfdc';
import * as translationsActions from '../../actions/types/activeProject';
import createReducer from '../createReducer';
import { initialState } from './translationsInitialState';
import { DEFAULT_LOCALE_CODE, defaultLanguageCode } from '../../../data/locales';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';

const cloneDeep = rfdc();

const getNewLanguageStructure = (language) => {
  return {
    language,
    welcome: {},
    items: {},
    configs: {},
    catalog: {},
  };
};

export default createReducer(initialState, {
  [translationsActions.SURVEY_FETCH_REQUEST]() {
    return cloneDeep(initialState);
  },
  [translationsActions.ACTIVE_PROJECT_TRANSLATION_FETCH_ONE](state) {
    const newState = cloneDeep(state);
    newState.fetching = true;
    return newState;
  },
  [translationsActions.ACTIVE_PROJECT_TRANSLATION_FETCH_ONE_SUCCESS](state, action) {
    const newState = cloneDeep(state);
    const { language = 'en', locale = DEFAULT_LOCALE_CODE } = action.response;
    if (isFeatureEnabled(Features.panel)) {
      newState.data[locale] = action.response;
    } else {
      newState.data[language] = action.response;
    }
    newState.fetching = false;
    return newState;
  },
  [translationsActions.ACTIVE_PROJECT_CHANGE_LANGUAGE_TO_TRANSLATE](state, action) {
    const selectedLanguage = action.payload;
    return { ...state, selectedLanguage };
  },
});
