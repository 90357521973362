import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IConjointPayload, IResponseStore } from './interfaces';
import { mixReducers } from '../../reducers/utils';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import oldResponsesReducer from '../../reducers/responses';

const initialState: IResponseStore = {};

const getFrontendIdAndPosition = (frontendId: string) => {
  const ids = frontendId.split('_');

  return {
    originalFrontendId: ids[0],
    position: ids[1] ? Number(ids[1]) : null,
  };
};

const responsesSlice = createSlice({
  name: 'responses',
  initialState,
  reducers: {
    setConjointResponse(state, action: PayloadAction<IConjointPayload>) {
      if (!isFeatureEnabled(Features.conjoint)) {
        return state;
      }
      const { frontendId, value } = action.payload;
      const { originalFrontendId, position } = getFrontendIdAndPosition(frontendId);
      if (position === null || !originalFrontendId) {
        return state;
      }
      const currentQuestion = state[originalFrontendId];
      if (currentQuestion && 'values' in currentQuestion && Array.isArray(currentQuestion.values)) {
        if (currentQuestion.values.length < position) {
          return;
        }
        (currentQuestion.values ?? []).splice(position, 1, value);
        return;
      }
      state[originalFrontendId] = {
        na: false,
        values: [value],
      };
    },
    deleteConjointResponse(state, action: PayloadAction<string>) {
      if (!isFeatureEnabled(Features.conjoint)) {
        return state;
      }
      const { originalFrontendId, position } = getFrontendIdAndPosition(action.payload);
      if (position === null || !originalFrontendId) {
        return state;
      }

      const currentQuestion = state[originalFrontendId];
      if (currentQuestion && 'values' in currentQuestion && Array.isArray(currentQuestion.values)) {
        (currentQuestion.values ?? []).splice(position, 1);
      }
    },
  },
});

export const { setConjointResponse, deleteConjointResponse } = responsesSlice.actions;
export const responsesReducer = mixReducers(responsesSlice.reducer, oldResponsesReducer);
