import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISurveyVariable {
  values: number[];
  byItem: {
    [frontendId: string]: number;
  };
}

export interface ISurveyVariablesInitialState {
  [_id: string]: ISurveyVariable;
}

const initialState: ISurveyVariablesInitialState = {};

const surveyVariablesSlice = createSlice({
  name: 'surveyVariables',
  initialState,
  reducers: {
    setSurveyVariables(state, action: PayloadAction<ISurveyVariablesInitialState>) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setSurveyVariables } = surveyVariablesSlice.actions;
export default surveyVariablesSlice.reducer;
